




















































































































/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable  @typescript-eslint/no-unused-vars */
import { Component, Vue, Watch } from "vue-property-decorator";
import _ from "lodash";
import { dbRef } from "@/common/Database";
import Overview from "@/models/Overview";

@Component
export default class AdminOverview extends Vue {
  dirty = false;

  overview: Overview = new Overview();

  originalOverview: Overview = new Overview();

  async mounted(): Promise<void> {
    this.overview = (await dbRef.overview.doc("1").get()).data()!;
    if (this.overview == null) {
      this.overview = new Overview();
    }
    this.originalOverview = { ...this.overview };
  }

  submit(): void {
    dbRef.overview.doc("1").set(this.overview);
    this.originalOverview = { ...this.overview };
    this.dirty = false;
  }

  @Watch("overview", {
    deep: true,
  })

  overviewChanged(newVal: Overview, oldVal: Overview) {
    this.dirty = !_.isEqual(this.originalOverview, newVal);
  }

  getTime(d: {
    getHours: () => string;
    getMinutes: () => string;
    getSeconds: () => string;
  }) {
    return `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`;
  }

  setTime(date: Date, newtime: string) {
    // Split the time string at the ':'s
    const hoursMinSecs = newtime.split(":");
    date.setHours(+hoursMinSecs[0]);
    date.setMinutes(+hoursMinSecs[1]);
    date.setSeconds(+hoursMinSecs[2]);
  }
}
