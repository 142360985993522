






































































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { sendContactFormEmail } from "@/common/email";
import { UserState } from "@/store";

@Component
export default class Contact extends Vue {
  private addressText = "10A Panther Place\nMt. Pearl, NL\nA1N 5B1";

  private name = "";

  private email = "";

  private message = "";

  private successMsg = "Thank you! Your message has been successfully sent."

  private dispMsg = false;

   @Getter
  public userState!: UserState;

   created(): void {
     if (this.userState.data.email) {
       this.email = this.userState.data.email;
       this.name = `${this.userState.data.firstName} ${this.userState.data.lastName}`;
     }
   }

   sendMessage(): void {
     if (this.name.trim().length && this.email.trim().length && this.message.trim().length) {
       sendContactFormEmail(this.name, this.email, this.message);
       this.dispMsg = true;
     }
   }

   private isComplete() {
     if (this.message !== "" && this.name !== "" && this.email !== "" && this.isValidEmail()) {
       return true;
     }
     return false;
   }

   private isValidEmail(): boolean {
     let vEmail = false;
     const emailPattern = new RegExp(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b/);
     if (emailPattern.test(this.email)) {
       vEmail = true;
     }
     return vEmail;
   }
}
