import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import Access from "@/models/Access";
import Overview from "@/models/Overview";
import User from "../models/User";

Vue.use(Vuex);

export interface UserState {
  loggedIn: boolean;
  data: User;
  isAdmin: boolean;
  access: number;
  ticketNumSelected: number;
}

interface AppState {
  overview: Overview;
  userState: UserState,
  currentJackpot: number
  currentDraw: string;
  ticketCountdownTimer: string | undefined,
  banner: {
    show: boolean,
    message: string,
    type: string,
  },
}

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    userState: {
      loggedIn: false,
      data: new User(),
      isAdmin: false,
      access: Access.PUBLIC,
      ticketNumSelected: 0,
    },
    currentJackpot: 0,
    overview: new Overview(),
    currentDraw: "",
    ticketCountdownTimer: undefined,
    banner: {
      show: false,
      message: "",
      type: "",
    },
  } as AppState,
  getters: {
    userState: (state) => state.userState,
    data: (state) => state.userState.data,
    loggedIn: (state) => state.userState.loggedIn,
    ticketNumSelected: (state) => state.userState.ticketNumSelected,
    currentJackpot: (state) => state.currentJackpot,
    overview: (state) => state.overview,
    currentDraw: (state) => state.currentDraw,
    ticketCountdownTimer: (state) => (state.ticketCountdownTimer
      ? new Date(state.ticketCountdownTimer)
      : undefined),
    banner: (state) => state.banner,
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.userState.loggedIn = value;
    },
    SET_USER(state, data) {
      state.userState.data = data;
    },
    SET_ADMIN(state, data) {
      state.userState.isAdmin = data;
    },
    SET_ROLE(state, data) {
      state.userState.access = data;
    },
    SET_TICK_NUM_SELECTED(state, tick) {
      state.userState.ticketNumSelected = tick;
    },
    SET_JACKPOT(state, money) {
      state.currentJackpot = money;
    },
    SET_OVERVIEW(state, data) {
      state.overview = data;
    },
    SET_CURRENT_DRAW(state, draw) {
      state.currentDraw = draw;
    },
    SET_TICKET_COUNTDOWN_TIMER(state, date: Date | undefined) {
      if (date === undefined) {
        state.ticketCountdownTimer = date;
      } else {
        state.ticketCountdownTimer = date.toString();
      }
    },
    SHOW_BANNER(state, data) {
      state.banner.show = data.show;
      state.banner.message = data.message;
      state.banner.type = data.type;
    },
  },
  actions: {
    async fetchUser({ commit }, user) {
      if (user) {
        // Set loggedIn first, as some places we wait on user
        // (and we want to make sure they are both set)
        commit("SET_LOGGED_IN", true);
        commit("SET_USER", user);
      } else {
        commit("SET_USER", undefined);
        commit("SET_ADMIN", false);
        commit("SET_ROLE", Access.PUBLIC);
        commit("SET_TICK_NUM_SELECTED", 0);
        commit("SET_TICKET_COUNTDOWN_TIMER", undefined);
        commit("SET_LOGGED_IN", false);

        window.sessionStorage.clear();
      }
    },
    async setRole({ commit }, access) {
      commit("SET_ROLE", access);
    },
    async setLoggedIn({ commit }, value) {
      commit("SET_LOGGED_IN", value);
    },
    async setTickNumSelected({ commit }, value) {
      commit("SET_TICK_NUM_SELECTED", value);
    },
    async setJackpot({ commit }, value) {
      commit("SET_JACKPOT", value);
    },
    async setOverview({ commit }, value) {
      commit("SET_OVERVIEW", value);
    },
    async setCurrentDraw({ commit }, value) {
      commit("SET_CURRENT_DRAW", value);
    },
    async setTicketCountdownTimer({ commit }, value) {
      commit("SET_TICKET_COUNTDOWN_TIMER", value);
    },
    async showBanner({ commit }, { message, type }) {
      commit("SHOW_BANNER", { show: true, message, type });
      setTimeout(() => commit("SHOW_BANNER", { show: false, message: "", type: "" }), 4000);
    },
  },
});
