






















































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import firebase from "firebase/app";
import "firebase/firestore";
import { getTicketsQuery } from "@/common/QueryClient";
import store, { UserState } from "@/store";
import { Ticket } from "@/models/Ticket";
import db from "@/common/Database";
import { Subscription } from "@/models/Subscription";
import User from "../models/User";
import { query } from "../models/Query";

interface userTicketsPlayed extends Ticket {
  played: boolean;
}

interface extSub extends Subscription {
  created?: number;
  status?: string;
}

@Component
export default class PlayNow extends Vue {
  private tickOptions: Array<Ticket> = [];

  private existingTicks: Array<Ticket> = [];

  private existingTickNums: Array<number> = [];

  private ticketSelected = 0;

  private numOptions = 3;

  private hidden = true;

  private query = query;

  private userData = new User();

  private count = 0;

  private userTickets: Array<userTicketsPlayed> = [];

  private maxNumber = 100;

  drawEndDate: Date | undefined = undefined;

  drawStartDate: Date | undefined = undefined;

  // ! enforces no undefined on getters
  @Getter
  public currentDraw!: string;

  @Getter
  public userState!: UserState;

  @Getter
  public loggedIn!: boolean;

  newNumber = false;

  drawTickets: string[] = [];

  async created(): Promise<void> {
    // If the user came to this page directly,
    // we need to make sure some state variables are set correctly
    // before we proceed.  This waits for the 'currentDraw' store
    // variable to be set before preceeding.
    if (window.screen.width < 600) {
      const divElement = document.getElementById("top-div");
      if (divElement) divElement.scrollIntoView();
    }
    if (!store.state.currentDraw.length) {
      store.watch(
        (state) => state.currentDraw,
        (value) => {
          if (value.length) {
            this.initPage();
          }
        },
      );
    } else {
      this.initPage();
    }
  }

  async initPage(): Promise<void> {
    // First get the list of tickets that are paid for
    // (that is, the tickets in the Draw table)
    // that are assigned to the logged in user
    // (matching on stripe customer ID)
    await db.draws
      .doc(this.currentDraw)
      .onSnapshot((snap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
        snap.ref
          .collection("Tickets")
          // Should use a where clause here? YES
          .onSnapshot(
            (ticket: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
              if (this.loggedIn) {
                ticket.forEach((t) => {
                  const currentTicket = t.data();
                  if (this.userState.data.customerStripeId === currentTicket.customerStripeId) {
                    this.drawTickets.push(t.id);
                  }
                });
              }
            },
          );
      });

    this.existingTicks = await getTicketsQuery(this.query);
    this.existingTicks.forEach((tick) => {
      this.existingTickNums.push(tick.TicketNumber);
    });
    if (this.loggedIn && this.userState.data.id) {
      const tempUserTickets = this.existingTicks.filter((t) => t.uid === this.userState.data.id);

      // await in loops can be a performance issue, but we know
      // this loop will always be quite small.
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < tempUserTickets.length; i++) {
        const played = await this.isPlayed(tempUserTickets[i].TicketNumber);
        this.userTickets.push({ played, ...tempUserTickets[i] });
      }
    }
    const dates = this.currentDraw.split("-");
    this.drawEndDate = new Date(
      parseInt(dates[1].substring(0, 4), 10),
      // our dates are numbers representing months starting at 1
      // js Date object requires them to start at 0
      parseInt(dates[1].substring(4, 6), 10) - 1,
      parseInt(dates[1].substring(6), 10),
    );
    this.drawStartDate = new Date(
      parseInt(dates[0].substring(0, 4), 10),
      // our dates are numbers representing months starting at 1
      // js Date object requires them to start at 0
      parseInt(dates[0].substring(4, 6), 10) - 1,
      parseInt(dates[0].substring(6), 10),
    );
    this.generateTickets();
  }

  generateTickets(): void {
    this.tickOptions = [];
    const currentNumbers: number[] = [];

    // generate numOptions(3) random numbers
    for (let i = 0; i < this.numOptions; i++) {
      const ticketNum = Math.floor(Math.random() * this.maxNumber + 1);
      const tick = new Ticket();
      tick.TicketNumber = ticketNum;
      this.tickOptions.push(tick);
    }

    // tickOptions now has 3 Tickets with random #s
    const len = this.tickOptions.length;
    let attempts = 0;
    const maxAttempts = 4;
    this.maxNumber = 100;
    // Now make sure the 3 generated numbers arent used anywhere else
    while (this.count < len) {
      // if the # is already in our list
      // OR # is already in the global list
      if (
        currentNumbers.includes(this.tickOptions[this.count].TicketNumber)
        || this.existingTickNums.includes(this.tickOptions[this.count].TicketNumber)
      ) {
        // console.log("Number taken, regen: ", this.tickOptions[this.count].TicketNumber);
        // regenerate the number
        // console.log(`Number already exists: ${this.tickOptions[this.count].TicketNumber}`);
        this.tickOptions[this.count].TicketNumber = Math.floor(Math.random() * this.maxNumber) + 1;
      } else {
        // this # is good
        currentNumbers.push(this.tickOptions[this.count].TicketNumber);
        this.count++;
        attempts = 0;
      }
      if (attempts >= maxAttempts) {
        attempts = 0;
        this.maxNumber *= 5;
        // console.log("Increasing Max Number: ", this.maxNumber);
      }
      attempts++;
      // console.log(`attempts: ${attempts}, count: ${this.count}`);
    }
    this.count = 0;
  }

  setNewNumber(value: boolean): void {
    this.newNumber = value;
  }

  async isPlayed(ticket: number): Promise<boolean> {
    // If ticket is in the Draw then it's in play
    if (this.drawTickets.includes(ticket.toString())) return true;

    // Now check if it's an active subscription
    const subSnap = await db.subscriptions.where("TicketNumber", "==", ticket).get();
    if (!subSnap.empty) {
      const subscriptions: Array<extSub> = subSnap.docs.map((doc) => doc.data());
      subscriptions.sort(
        (a: extSub, b: extSub) => (b.created ? b.created : 0) - (a.created ? a.created : 0),
      );

      if (subscriptions[0].status === "active") return true;
    }

    return false;
  }
}
