import Vue from "vue";
import "firebase/firestore";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import TicketNumber from "@/components/TicketNumber.vue";
import TotalPrizeMoney from "@/components/TotalPrizeMoney.vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("ticket-number", TicketNumber);
Vue.component("total-prize-money", TotalPrizeMoney);

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});

app.$mount("#app");
