






























































































/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Component, Vue } from "vue-property-decorator";
import { RouteConfig } from "vue-router";
import { Getter } from "vuex-class";
import firebase from "firebase/app";
import TicketNumber from "@/components/TicketNumber.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import Banner from "@/components/Banner.vue";
import TicketExpiry from "@/components/TicketExpiry.vue";
import store, { UserState } from "@/store";
import { dbRef } from "@/common/Database";
import { isGameActive } from "@/models/Overview";
import utils from "@/common/Utils";
import { routes } from "./router";

@Component({
  components: {
    CustomFooter,
    TicketNumber,
    Banner,
    TicketExpiry,
  },
})
export default class App extends Vue {
  private days = "";

  private hours = "";

  private minutes = "";

  // ! enforces no undefined on getters
  @Getter
  public userState!: UserState;

  @Getter
  public loggedIn!: boolean;

  @Getter
  public ticketCountdownTimer: Date | undefined = undefined;

  private isMobile = utils.isMobile();

  async created(): Promise<void> {
    // Load data from Overview page
    const overview = (await dbRef.overview.doc("1").get()).data()!;
    overview.loaded = true;
    await store.dispatch("setOverview", overview);

    // Set page title
    document.title = store.state.overview.name;

    // set up the countdown timer
    this.getCountdown();
    setInterval(this.getCountdown, 10000);

    // setup the jackpot monitor.
    firebase
      .firestore()
      .collection("CurrentDraw")
      .doc("current")
      .onSnapshot(
        async (
          snap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
        ) => {
          const drawid = snap?.data()?.id;
          // now store it in the store for everyone to use
          await store.dispatch("setCurrentDraw", drawid);
          firebase
            .firestore()
            .collection("Draws")
            .doc(drawid)
            .onSnapshot(
              async (
                snap2: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
              ) => {
                const money = snap2.data();
                let jackpot = money?.carry_over_money;
                jackpot += money?.one_time_money;
                jackpot += money?.subscription_money;

                // now store it in the store for everyone to use
                await store.dispatch("setJackpot", jackpot);
              },
              async () => {
                await store.dispatch("setJackpot", 0);
              },
            );
        },
        async () => {
          await store.dispatch("setJackpot", 0);
        },
      );
  }

  async logUserOut(): Promise<void> {
    await firebase
      .auth()
      .signOut()
      .catch((/* e */) => {
        // console.log(e.message);
      });
    this.$router.push({ name: "Home" });
  }

  viewableroutes(): RouteConfig[] {
    return routes.filter((item) => {
      if (item.meta) {
        if (item.meta.hidden === true) {
          return false;
        }
        // Test if the  user has the required role
        // eslint-disable-next-line
        return item.meta.roleReqd & this.userState.access ? true : false;
      }
      return false;
    });
  }

  /**
   * getCountdown
   */
  private getCountdown(): void {
    const now = new Date();
    let days = now.getDay() > 0 ? 7 - now.getDay() : 0;
    const end = this.addDays(now, days);
    const dif = end.getTime() - now.getTime();

    days = Math.trunc(dif / (24 * 60 * 60 * 1000));
    let hours = dif - days * (24 * 60 * 60 * 1000);
    hours = Math.trunc(hours / (60 * 60 * 1000));
    let mins = dif - (days * (24 * 60 * 60 * 1000) + hours * (60 * 60 * 1000));
    mins = Math.trunc(mins / (60 * 1000));

    this.days = `${String(days).padStart(2, "0")}`;
    this.hours = `${String(hours).padStart(2, "0")}`;
    this.minutes = `${String(mins).padStart(2, "0")}`;
  }

  private isActive() {
    return isGameActive(store.state.overview);
  }

  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    result.setHours(23, 55, 0);
    return result;
  }
}
