const GetLastDrawID = (drawID: string): string => {
  const start = new Date();
  start.setFullYear(parseInt(drawID.substring(0, 4), 10))
  start.setMonth(parseInt(drawID.substring(4, 6), 10) - 1)
  start.setDate(parseInt(drawID.substring(6, 8), 10))
  start.setDate(start.getDate() - 7);

  const end = new Date(start);
  // end.setFullYear(parseInt(drawID.substring(9, 13), 10))
  // end.setMonth(parseInt(drawID.substring(13, 15), 10) - 1)
  // end.setDate(parseInt(drawID.substring(15, 17), 10))
  // end.setDate(end.getDate() - 7);
  end.setDate(end.getDate() + 6);

  // console.log(start);
  // console.log(end);
  let prevDrawID = `${start.getFullYear()}`;
  prevDrawID += `${String(start.getMonth() + 1).padStart(2, "0")}`;
  prevDrawID += `${String(start.getDate()).padStart(2, "0")}-`;
  prevDrawID += `${end.getFullYear()}`;
  prevDrawID += `${String(end.getMonth() + 1).padStart(2, "0")}`;
  prevDrawID += `${String(end.getDate()).padStart(2, "0")}`;
  return prevDrawID;
}

const getDateFromDrawID = (id: string): Date[] => {
  const dates = id.split("-");
  const drawStartDate = new Date(
    parseInt(dates[0].substring(0, 4), 10),
    // our dates are numbers representing months starting at 1
    // js Date object requires them to start at 0
    parseInt(dates[0].substring(4, 6), 10) - 1,
    parseInt(dates[0].substring(6), 10),
  );
  drawStartDate.setMinutes(drawStartDate.getMinutes() - 5);
  drawStartDate.setSeconds(1);
  const drawEndDate = new Date(
    parseInt(dates[1].substring(0, 4), 10),
    // our dates are numbers representing months starting at 1
    // js Date object requires them to start at 0
    parseInt(dates[1].substring(4, 6), 10) - 1,
    parseInt(dates[1].substring(6), 10),
  );
  drawEndDate.setMinutes(drawEndDate.getMinutes() - 5);
  drawEndDate.setDate(drawEndDate.getDate() + 1);
  return [drawStartDate, drawEndDate];
}

// Convert Date to a timestamp(number)
const getTimeStamp = (date: Date): number => {
  const timestamp = parseInt((date.getTime() / 1000).toFixed(0), 10);
  return timestamp;
}
const getBillingAnchor = (): number => {
  // eslint-disable-next-line
  let dateToAnchor = new Date();
  // Set anchor to next monday at 9:00. If today is Monday we get next Monday
  dateToAnchor.setDate(dateToAnchor.getDate() + (((1 + 7 - dateToAnchor.getDay()) % 7) || 7));
  dateToAnchor.setHours(9, 0, 0);
  const anchor = getTimeStamp(dateToAnchor);
  return anchor;
}

const getBackDate = (): number => {
  // Get the previous Monday, even if today is Monday
  const date = new Date();
  const day = date.getDay();
  // eslint-disable-next-line
  let prevMonday = new Date();
  // if today is Sunday
  if (day === 0) {
    // Today is Sunday; go back 6 days to Monday
    prevMonday.setDate(date.getDate() - 6);
  } else {
    // Today is NOT Sunday
    prevMonday.setDate(date.getDate() - (day - 1));
  }

  prevMonday.setHours(9, 0, 0);

  return getTimeStamp(prevMonday);
}

const isMobile = (): boolean => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  }
  return false
}

export default {
  GetLastDrawID,
  getDateFromDrawID,
  getTimeStamp,
  getBillingAnchor,
  getBackDate,
  isMobile,
}
