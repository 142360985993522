







import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import store from "@/store";
import db from "@/common/Database";

@Component
export default class TicketExpiry extends Vue {
  @Getter
  public ticketNumSelected!: number;

  @Getter
  public ticketCountdownTimer!: Date;

  counter = "";

  mounted():void {
    setInterval(this.getTimeUntilTicketExpires.bind(this), 1000);
  }

  async getTimeUntilTicketExpires(): Promise<void> {
    if (this.ticketCountdownTimer === undefined) {
      this.counter = "";
      return;
    }
    const now = new Date().getTime();
    const distance = this.ticketCountdownTimer !== undefined
      ? this.ticketCountdownTimer.getTime() - now
      : 0;
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    if (distance < 0) {
      // number expired.
      const expiredNumber = this.ticketNumSelected;
      await store.dispatch("setTickNumSelected", 0);
      store.dispatch("setTicketCountdownTimer", undefined);
      // console.log("Cleared store.");
      // console.log(this.ticketNumSelected);
      this.counter = "Ticket has expired. Please select another.";
      db.tickets.where("TicketNumber", "==", expiredNumber).get().then((tickets) => tickets.forEach((ticket) => {
        // if (ticket.data().TicketNumber === expiredNumber) {
        ticket.ref.delete()
        // }
      }));
      // if the timer has expired, send them back to the home page
      this.$router.push("/");
      // if (this.$route.name === "Checkout") {
      //   this.$router.push("/playNow");
      // }
    }
    this.counter = `Expires in: ${minutes}m ${seconds}s`;
  }
}
