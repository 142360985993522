



























import {
  Component, Prop, Vue,
} from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Ticket, TicketStatus } from "@/models/Ticket";
import store, { UserState } from "@/store";
import db from "@/common/Database";

@Component
export default class TicketNumber extends Vue {
  @Prop({ required: true })
  ticket: Ticket = { uid: "", TicketNumber: -1, status: "" };

  @Prop({ required: true })
  readonly index!: number;

  @Prop({ required: true })
  readonly played!: boolean;

  @Getter
  userState!: UserState;

  // @Getter
  // public ticketNumSelected!: number;

  @Getter
  public loggedIn!: boolean;

  delay = "";

  mounted(): void {
    this.delay = `delay-animation--${this.index}`;
  }

  // @Watch("ticketNumSeleted")
  // onPropertyChanged(newVal: number, oldVal: number): void {
  //   console.log("TicketNumChanged");
  //   console.log(oldVal, newVal);
  // }

  getButtonText(): string {
    if (this.played) {
      return "Already Playing";
    }
    // if (!this.ticket.status) {
    //   return "Play this Number!";
    // }
    return "Play this Number!";
  }

  async onClick(): Promise<void> {
    // console.log("onClick");
    // console.log(this.ticket);
    if (this.played) return;

    // make SURE this number not already in play
    // const tikSnap = await db.tickets.where("TicketNumber", "==", this.ticket.TicketNumber).get();
    // if (!tikSnap.empty) {
    //   // Should maybe log the user out here or something?
    //   return;
    // }

    // Update the ticket number in the store
    store.dispatch("setTickNumSelected", this.ticket.TicketNumber);

    if (this.ticket.status === "") {
      this.ticket.status = TicketStatus.Reserved;
      if (this.loggedIn && this.userState.data.id) {
        this.ticket.uid = this.userState.data.id;
      }
      db.tickets.add(this.ticket);
      const date = new Date();
      const datePlusFifteenMinutes = new Date(date);
      datePlusFifteenMinutes.setMinutes(
        date.getMinutes() + parseInt(process.env.VUE_APP_TICKET_EXPIRY_TIME_MINUTES || 15, 10),
      );
      store.dispatch("setTicketCountdownTimer", datePlusFifteenMinutes);
    } else {
      store.dispatch("setTicketCountdownTimer", undefined);
    }
    this.$router.push("/checkout");
  }
}
