




























































































import { Component, Vue } from "vue-property-decorator";
import { isGameActive } from "@/models/Overview";
import store from "@/store";

@Component
export default class HowToPlay extends Vue {
  private isActive() {
    return isGameActive(store.state.overview);
  }
}
