

























































































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import firebase from "firebase";
import db from "@/common/Database";
import store, { UserState } from "@/store";
import { Ticket, TicketStatus } from "@/models/Ticket";
import Draw from "@/models/Draw";
import User from "@/models/User";
import utils from "@/common/Utils";
import { sendWinnerEmail } from "@/common/email";
import {
  isAfterEndDate,
} from "@/models/Overview";

interface UserTix extends User {
  tickets: number[];
}

@Component
export default class Admin extends Vue {
  @Getter
  userState!: UserState;

  @Getter
  currentDraw!: string;

  lastDraw: Draw = new Draw();

  lastDrawDates: Date[] = [];

  drawJackpot = 0;

  drawTickets: string[] = [];

  drawCSV: string[][] = [];

  tickets: string[] = [];

  newTicketsThisWeek: string[] = [];

  newTicketsThisWeekCSV: string[][] = [];

  winningNumber: number | null = null;

  drawCompleted = false;

  status = "";

  file: File | null = null;

  async mounted(): Promise<void> {
    const users = await (await db.users.get()).docs;
    const activeDraws = await (await db.draws.get()).docs;
    activeDraws.sort((a, b) => (`${b.id}`).localeCompare(a.id));
    console.log(activeDraws);

    console.log(this.currentDraw);
    let prevDrawID = utils.GetLastDrawID(this.currentDraw);
    if (isAfterEndDate(store.state.overview)) {
      // special case for the last draw of the contest
      prevDrawID = activeDraws[0].id;
    }

    // Find the previous draw from the db and the current draw
    this.checkIfAlreadyDrawn(prevDrawID);
    console.log(`PrevDraw ID: ${prevDrawID}`);
    activeDraws.forEach((draw: firebase.firestore.QueryDocumentSnapshot<Draw>) => {
      // console.log(draw.id);
      if (draw.id === prevDrawID) {
        // console.log("matched draw");
        this.lastDraw = { ...draw.data(), id: draw.id };
        this.lastDrawDates = this.getDateFromDrawID(draw.id);
      }
    });
    console.log(this.lastDraw);
    if (this.lastDraw.id) {
      const draw = await db.draws.doc(this.lastDraw.id).get();
      const drawData = draw.data();
      if (drawData) {
        this.drawJackpot = drawData.carry_over_money
          + drawData.one_time_money + drawData.subscription_money;
      }

      // Get all tickets in the Draw
      const dbDrawTickets = await draw.ref.collection("Tickets").get();
      dbDrawTickets.forEach((t) => {
        this.drawTickets.push(t.id);
      });
    }

    // Get all regged tickets
    const allTickets = await db.tickets.get();
    // console.log("Loaded Tickets");
    // console.log(this.lastDrawDates);
    // Loop thru all tickets and include ones
    // that are NOT Reserved
    await allTickets.forEach((doc) => {
      // console.log(doc.data().TicketNumber);
      if (doc.data().status !== TicketStatus.Reserved) {
        // console.log("Ticket good");
        this.tickets.push(doc.data().TicketNumber.toString());
        const user = users.find((u) => doc.data().uid === u.id);
        const createdAt = new Date(doc.data().CreatedAt as string);
        if (user && createdAt <= this.lastDrawDates[1]) {
          // console.log("adding ticket");
          this.drawCSV.push([
            doc.data().TicketNumber.toString(),
            createdAt.toString(),
            user.data().email,
          ]);
        } else {
          // console.log(user);
          // console.log(createdAt);
          // console.log(this.lastDrawDates[1]);
        }
      }
    });
    // Sort the tickets by number (?)
    this.tickets.sort((a, b) => parseInt(a, 10) - parseInt(b, 10));
    await allTickets.forEach((doc) => {
      const ticket = doc.data();
      if (ticket.CreatedAt) {
        const ticketCreationDate = new Date(ticket.CreatedAt);
        if (
          ticketCreationDate >= this.lastDrawDates[0]
          && ticketCreationDate <= this.lastDrawDates[1]
          && ticket.status !== TicketStatus.Reserved
        ) {
          const user = users.find((u) => ticket.uid === u.id);
          if (user) {
            this.newTicketsThisWeek.push(ticket.TicketNumber.toString());
            this.newTicketsThisWeekCSV.push([
              ticket.TicketNumber.toString(),
              ticket.CreatedAt.toString(),
              user.data().email,
            ]);
          }
        }
      }
    });
  }

  async checkIfAlreadyDrawn(prevDrawID: string): Promise<void> {
    const draw = await db.draws.doc(prevDrawID).get();
    if (draw.exists) {
      if (draw.data()?.draw_completed) {
        this.winningNumber = draw.data()?.winning_number as number;
        this.drawCompleted = true;
      }
    }
  }

  findWinningNumber(): void {
    if (this.winningNumber !== null) {
      if (this.drawTickets.includes(this.winningNumber.toString())) {
        this.status = "Paid. There is a winner!";
      } else if (this.tickets.includes(this.winningNumber.toString())) {
        this.status = "Exists but not paid. Pot will roll over.";
      } else {
        this.status = "Doesn't exist. Please enter a number that exists.";
      }
    } else {
      this.status = "Please select a number before clicking submit.";
    }
  }

  sendWinnerEmail(): void {
    // console.log(`sendWinnerEmail: ${this.winningNumber}`);
    if (this.winningNumber) {
      const drawTotal = this.lastDraw.subscription_money
        + this.lastDraw.one_time_money
        + this.lastDraw.carry_over_money;
      const theWinningNumber: number = this.winningNumber;
      // Based on the ticket #, look up the ticket record, and get the userID, then
      // use the userID to get the user record to send the email...
      db.tickets.where("TicketNumber", "==", theWinningNumber).get().then((docs) => {
        // console.log(docs);
        if (!docs.empty) {
          // console.log("Got docs");
          const tix = docs.docs;
          tix.forEach((ticketDoc) => {
            const ticket = ticketDoc.data() as Ticket;
            const { uid } = ticket;
            // console.log(uid);
            // now get the user record.
            db.users.doc(uid).get().then((user) => {
              if (user.exists && user.data()) {
                // console.log(drawTotal);
                // const userTic = user.data() as UserTix;
                sendWinnerEmail(user.data() as User, drawTotal);
                // found = true;
              }
            })
          });
        }
      });
    }
  }

  submit(): void {
    // console.log("submit");
    if (!this.lastDrawDates.length) {
      store.dispatch("showBanner", {
        type: "error",
        message: "Draw has already been completed for this week",
      });
    } else if (this.winningNumber !== null) {
      // const drawTotal = this.lastDraw.subscription_money
      //   + this.lastDraw.one_time_money
      //   + this.lastDraw.carry_over_money;

      // const gotWinner = this.drawTickets.includes(this.winningNumber.toString());
      if (this.drawTickets.includes(this.winningNumber.toString())) {
        // There IS a winner
        db.draws.doc(this.lastDraw.id).set({
          ...this.lastDraw,
          winning_number: this.winningNumber,
          winning_ticket_paid: true,
          adminUID: this.userState.data.id,
          draw_completed: true,
        });
        db.draws.doc(this.currentDraw).update({
          winning_number: this.winningNumber,
          winning_ticket_paid: true,
          carry_over_money: 0,
        });
        // Based on the ticket #, look up the ticket record, and get the userID, then
        // use the userID to get the user record to send the email...
        // db.tickets.where("TicketNumber", "==", this.winningNumber).get().then((docs) => {
        //   if (!docs.empty) {
        //     const tix = docs.docs;
        //     tix.forEach((ticketDoc) => {
        //       const ticket = ticketDoc.data() as Ticket;
        //       const { uid } = ticket;

        //       // now get the user record.
        //       db.users.doc(uid).get().then((user) => {
        //         if (user.exists && user.data()) {
        //           // const userTic = user.data() as UserTix;
        //           sendWinnerEmail(user.data() as User, drawTotal);
        //           // found = true;
        //         }
        //       })
        //     });
        //   }
        // });
        // db.users.get().then((snap) => {
        //   const users = snap.docs;
        //   let found = false;
        //   users.forEach((user) => {
        //     const userTic = user.data() as UserTix;
        //     if (!found && this.winningNumber && userTic.tickets.includes(this.winningNumber)) {
        //       sendWinnerEmail(user.data(), drawTotal);
        //       found = true;
        //     }
        //   });
        // });
        store.dispatch("showBanner", { type: "success", message: "Draw Completed" });
      } else if (this.tickets.includes(this.winningNumber.toString())) {
        // No Winner
        db.draws.doc(this.lastDraw.id).set({
          ...this.lastDraw,
          winning_number: this.winningNumber,
          winning_ticket_paid: false,
          adminUID: this.userState.data.id,
          draw_completed: true,
        });
        db.draws.doc(this.currentDraw).update({
          winning_number: this.winningNumber,
          winning_ticket_paid: false,
        });
        store.dispatch("showBanner", { type: "success", message: "Draw Completed" });
      } else {
        this.status = "Please select a number in the draw before submitting.";
      }
    }
  }

  // Monday 00:00:01am to Sunday 23:59:59
  getDateFromDrawID(id: string): Date[] {
    const dates = id.split("-");
    const drawStartDate = new Date(
      parseInt(dates[0].substring(0, 4), 10),
      // our dates are numbers representing months starting at 1
      // js Date object requires them to start at 0
      parseInt(dates[0].substring(4, 6), 10) - 1,
      parseInt(dates[0].substring(6), 10),
    );
    const drawEndDate = new Date(
      parseInt(dates[1].substring(0, 4), 10),
      // our dates are numbers representing months starting at 1
      // js Date object requires them to start at 0
      parseInt(dates[1].substring(4, 6), 10) - 1,
      parseInt(dates[1].substring(6), 10),
    );
    drawEndDate.setHours(23, 59, 59);
    return [drawStartDate, drawEndDate];
  }

  async parseCSV(): Promise<void> {
    if (this.file !== null) {
      const text = await this.file.text();
      const textArray = text.split("\n");
      const data: string[][] = [];
      textArray.forEach((row) => {
        data.push(row.split(","));
      });
      const users: UserTix[] = [];
      data.forEach((row, index) => {
        // skip db row names and empty rows
        if (index > 0 && row.length > 2) {
          const i = users.findIndex((u) => u.email === row[2]);
          if (i !== -1) {
            const user = users[i];
            user.tickets.push(parseInt(row[13], 10));
          } else {
            const user = new User() as UserTix;
            [, , user.email] = row;
            user.tickets = [parseInt(row[13], 10)];
            user.fromWordpress = true;
            if (user.email !== undefined) {
              users.push(user);
            }
          }
        }
      });
      users.forEach((user) => {
        db.users.add(user).then((u) => {
          user.tickets.forEach((ticket) => {
            const t = new Ticket(ticket, u.id, TicketStatus.InUse);
            db.tickets.add(t);
          });
        });
      });
    }
  }

  exportToCSV(arrayToExport: string[][], title: string): void {
    // Sort array first
    // console.log("export list");
    // console.log(arrayToExport);
    arrayToExport.sort(
      (a: string[], b: string[]) => new Date(a[1]).getTime() - new Date(b[1]).getTime(),
    );
    const array = arrayToExport.map(
      (entry: string[]) => `${entry.map((item: string) => `"${item}"`)}\n`,
    );
    const csvFile = new Blob([...array], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    // sets the name for the download file
    const date = new Date();
    const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    downloadLink.download = `${dateString}-${title}-report.csv`;
    // sets the url to the window URL created from csv file above
    downloadLink.href = window.URL.createObjectURL(csvFile);
    // creates link, but does not display it.
    downloadLink.style.display = "none";
    // add link to body so click function below works
    document.body.appendChild(downloadLink);

    downloadLink.click();
  }
}
