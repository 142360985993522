import User from "@/models/User";
import { fs } from "@/common/Database";
import store from "@/store";

const emailTable = "mail";
const testMode = false;

export enum emailTemplates {
  WELCOME = "welcome",
  WINNER = "winner",
  UPDATE = "update",
  REMINDER = "reminder",
  DEFAULT = "default",
  CONTACT = "contact",
  PURCHASE = "purchase",
}

interface emailDetails {
  to: Array<string>;
  cc: Array<string>;
  bcc: Array<string>;
  data: Record<string, unknown>;
  template: string;
}

function sendEmail(details: emailDetails):void {
  if (!testMode) {
    // Should now have everything we need to send the email(s)
    fs.collection(emailTable).add({
      to: details.to.join(),
      cc: details.cc.join(),
      bcc: details.bcc.join(),
      template: {
        name: details.template,
        data: {
          ...details.data,
          license: store.state.overview.lotteryLicense,
          year: new Date().getFullYear().toString(),
        },
      },
    }).catch((e) => console.log(e));
  }
}

export function sendContactFormEmail(name: string, email: string, msg: string) :void {
  const to = new Array<string>();
  to.push("info@trailstoriches.ca");
  const data = { name, email, msg };
  const template = emailTemplates.CONTACT;
  sendEmail({
    to,
    cc: [],
    bcc: [],
    data,
    template,
  });
}

export function sendPurchaseEmail(u: User, template = emailTemplates.PURCHASE):void {
  const to = new Array<string>();
  to.push(u.email);

  sendEmail({
    to,
    cc: [],
    bcc: [],
    data: {},
    template,
  });
}

export function sendWinnerEmail(
  user: User,
  drawTotal: number,
  template = emailTemplates.WINNER,
): void {
  const to = new Array<string>();
  to.push(user.email);
  sendEmail({
    to,
    cc: [],
    bcc: [],
    data: {
      name: user.firstName,
      drawTotal: drawTotal / 2,
    },
    template,
  });
}

export function sendWelcomeEmail(
  to: string,
  template = emailTemplates.WELCOME,
): void {
  const toEmails = new Array<string>();
  toEmails.push(to);
  sendEmail({
    to: toEmails,
    cc: [],
    bcc: [],
    data: {},
    template,
  });
}

export function sendUnpaidNumber(
  user: User,
  template = emailTemplates.REMINDER,
): void {
  const to = new Array<string>();
  to.push(user.email);
  sendEmail({
    to,
    cc: [],
    bcc: [],
    data: { name: user.firstName },
    template,
  });
}
/* Below here is the code for batching emails, which maybe should be implemented at some point...

// Batch the emails to keep SES happy (max 50 recips per email)
        try {
          const emailsLen = emails.length;
          let emailsBatch = [];
          for (let i = 0; i < emailsLen; i += 50) {
            emailsBatch = emails.slice(i, i + 50);

            // no 'to' or 'cc' as we want to keep the recipients anonymous from each other
            await fs
              .collection("emailNotify")
              .add({
                bcc: emailsBatch.join(),
                message: {
                  subject: ann.title,
                  text: ann.text,
                  attachments: attachments,
                },
              })
              .then(() => {
                console.log(
                  `Batch #${i / 50 + 1} sent, ${emailsBatch.length} emails!`
                );
              });
          }

          this.toast = this.$t("ann.email-success", {
            count: count,
          }).toString();
          this.toastColor = "success";
        } catch (error) {
          this.toast = this.$t("ann.email-fail").toString();
          this.toastColor = "failure";
          console.log("Error emailing");
          console.log(error);
        }

*/
