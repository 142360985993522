




















































import { Component, Vue } from "vue-property-decorator";
import {
  loadStripe,
  Stripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  Token,
  TokenResult,
} from "@stripe/stripe-js";
import { Getter } from "vuex-class";
import { fs, dbRef } from "@/common/Database";
import store, { UserState } from "@/store";
import User from "@/models/User";
import { getTicketsQuery } from "@/common/QueryClient";
import { sendPurchaseEmail } from "@/common/email";
import Overview from "@/models/Overview";
import utils from "@/common/Utils";
import { TicketStatus } from "../models/Ticket";
import { query } from "../models/Query";

/* eslint-disable camelcase */
interface StripeObject {
  customer: string;
  amount?: number;
  source: Token | string | undefined;
  TicketNumber: number;
  items?: { price: string }[];
  metadata?: { TicketNumber: number };
  billing_cycle_anchor?: number;
  backdate_start_date?: number;
  cancel_at?: number;
  // proration_behavior?: string;
}

const sleepNow = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay))

@Component
export default class Checkout extends Vue {
  private stripePromise = loadStripe(`${process.env.VUE_APP_FB_STRIPE_PUBLISHABLE_KEY}`);

  private cardNumber: StripeCardNumberElement | undefined;

  private cardExpiry: StripeCardExpiryElement | undefined;

  private cardCvc: StripeCardCvcElement | undefined;

  private stripeValidationError = "";

  private stripe: Stripe | null = null;

  private amount = 5;

  private success = false;

  private ticketPrice = 0;

  private subscription = true;

  private transactionType = "";

  private customerStripeId = "";

  private paymentLoading = false;

  private query = query;

  private cardNumberValue = "";

  private cardExpiryValue = "";

  private cardCVCValue = "";

  private plans: Array<{ text: string; value: boolean }> = [
    { text: "Weekly Subscription", value: true },
    { text: "One time Purchase", value: false },
  ];

  // ! enforces no undefined on getters
  @Getter
  public currentDraw!: string;

  @Getter
  public userState!: UserState;

  @Getter
  public ticketNumSelected!: number;

  // this is the logged in User
  @Getter
  public data!: User;

  private overview: Overview = new Overview();

  async mounted(): Promise<void> {
    if (!this.userState.loggedIn) {
      this.$router.push("/login");
    }
    if (this.ticketNumSelected === 0) {
      this.$router.push("/playNow");
    }
    // create stripe obj and elements
    this.stripe = await this.stripePromise;
    const elements = this.stripe?.elements();
    this.cardNumber = elements?.create("cardNumber");
    this.cardExpiry = elements?.create("cardExpiry");
    this.cardCvc = elements?.create("cardCvc");
    if (this.cardNumber && document.getElementById("card-number")) {
      this.cardNumber.mount("#card-number");
      // @ts-expect-error: Typescript overloads fail to find the overload but it exists
      this.cardNumber.on("change", this.setValidationError);
    }
    if (this.cardExpiry && document.getElementById("card-number")) {
      this.cardExpiry.mount("#card-expiry");
      // @ts-expect-error: Typescript overloads fail to find the overload but it exists
      this.cardExpiry.on("change", this.setValidationError);
    }
    if (this.cardCvc && document.getElementById("card-number")) {
      this.cardCvc.mount("#card-cvc");
      // @ts-expect-error: Typescript overloads fail to find the overload but it exists
      this.cardCvc.on("change", this.setValidationError);
    }
  }

  async placeOrder(): Promise<void> {
    // Get overview from store
    this.overview = this.$store.getters.overview;
    this.paymentLoading = true;
    // create token to store pmt info
    if (this.cardNumber !== undefined) {
      const token = await this.stripe?.createToken(this.cardNumber)
      if (token && token.error === undefined) {
        if (this.subscription) {
          // This is a Subscription charge
          // init subscription obj
          // 20221104, added the proration_behaviour, in an attempt to address the $0.03 bug
          const stripeObject = {
            customer: this.userState.data.customerStripeId,
            items: [{ price: `${process.env.VUE_APP_FB_STRIPE_TICKET_SUB}` }],
            source: token.token,
            TicketNumber: this.ticketNumSelected,
            // Add more meta here (and 1-time) to help make stripe records more informative...
            metadata: { TicketNumber: this.ticketNumSelected },
            billing_cycle_anchor: utils.getBillingAnchor(),
            backdate_start_date: utils.getBackDate(),
            cancel_at: utils.getTimeStamp(this.overview.endDate),
            proration_behavior: "none",
          };
          if (process.env.VUE_APP_NODE_ENV !== "production") {
            // if (process.env.VUE_APP_FB_STRIPE_SIGNING_KEY.includes("test")) {
            (stripeObject as StripeObject).source = "tok_visa";
            // }
          }
          await this.saveSubscription(stripeObject);
        } else {
          // init charge obj
          const stripeObject = {
            customer: this.userState.data.customerStripeId,
            amount: 5,
            source: token.token,
            TicketNumber: this.ticketNumSelected,
            metadata: { TicketNumber: this.ticketNumSelected },
          };
          if (process.env.VUE_APP_NODE_ENV !== "production") {
            // if (process.env.VUE_APP_FB_STRIPE_SIGNING_KEY.includes("test")) {
            (stripeObject as StripeObject).source = "tok_visa";
            // }
          }
          await this.saveCharge(stripeObject);
        }
      }
    }
  }

  // store charge obj in db
  async saveCharge(stripeObject: StripeObject): Promise<void> {
    const chargesRef = fs.collection("Charges");
    const docId = chargesRef.doc().id;

    // This writes to the Charges table, and triggers the cloud func (createStripeCharge) to run
    await fs
      .collection("Charges")
      .doc(docId)
      .set(stripeObject);

    // Now mark the ticket number as "payment pending"
    this.makeTicketPending(this.ticketNumSelected, "One-time payment pending");

    // Now we await the results of the cloud func; it writes back to the Charges table...
    chargesRef.doc(docId).onSnapshot(async (snapShot) => {
      const charge = snapShot.data();
      // console.log("Charge Snapshot");
      // console.log(charge);
      if (charge?.error) {
        this.paymentLoading = false;
        if (this.stripeValidationError === "") {
          store.dispatch("showBanner", {
            type: "error",
            message: "Something went wrong. Please check all numbers and try again.",
          });
        }
        await this.deletePending(this.ticketNumSelected);
        return;
      }
      // Don't do anything until the stripe data has been written
      if (charge && ("status" in charge)) {
        if (charge.status === "succeeded") {
          // console.log("succeeded");
          // eslint-disable-next-line
          (window as any).fbq("trackCustom", "PurchaseOneTime");

          this.updateTicket();

          this.success = true;

          // send email to user.
          sendPurchaseEmail(this.data);
          store.dispatch("showBanner", { type: "success", message: "Purchase Successful" });
          this.paymentLoading = false;
        } else {
          // console.log("NOT succeeded");
          this.stripeValidationError = "Oops - Looks like something went wrong while charging your card.  If the problem persists, please contact your financial institution.";
          store.dispatch("showBanner", {
            type: "error",
            message: this.stripeValidationError,
          });
          await this.deletePending(this.ticketNumSelected);
          this.paymentLoading = false;
        }
      }
    }, (error) => {
      this.stripeValidationError = error.message;
      this.paymentLoading = false;
      // console.log("error");
      // console.log(error);
    });
  }

  async deletePending(ticketNumSelected: number) : Promise<void> {
    // if there was a problem, we better delete the pending ticket
    // First make sure it's there and pending
    try {
      const snap = await fs
        .collection("Draws")
        .doc(this.currentDraw)
        .collection("Tickets")
        .doc(ticketNumSelected.toString())
        .get();

      if (snap.exists) {
        const ticket = snap.data();
        if (ticket && (ticket.type as string).indexOf("ending") > 0) {
          // found the pending ticket - delete it
          snap.ref.delete();
        }
      }
    } catch (error) {
      // Error reading ticket
    }
  }

  makeTicketPending(ticketNumSelected: number, status: string) : void {
    // Create a Draw/Ticket record with pending status
    fs.collection("Draws")
      .doc(this.currentDraw)
      .collection("Tickets")
      .doc(ticketNumSelected.toString())
      .set({ customerStripeId: this.userState.data.customerStripeId, type: status });
  }

  // store subscription obj in db
  async saveSubscription(stripeObject: StripeObject): Promise<void> {
    const subsRef = fs.collection("Subscriptions");
    const docId = subsRef.doc().id;
    const now = Date.now();

    await fs
      .collection("Subscriptions")
      .doc(docId)
      .set(stripeObject);

    // Now mark the ticket number as "payment pending"
    this.makeTicketPending(this.ticketNumSelected, "Subscription payment pending");

    // wait for the callback to update the status
    subsRef.doc(docId).onSnapshot(async (snapShot) => {
      const sub = snapShot.data();
      if (sub?.error) {
        this.paymentLoading = false;
        if (this.stripeValidationError === "") {
          store.dispatch("showBanner", {
            type: "error",
            message: "Something went wrong. Please check all numbers and try again.",
          });
        }
        await this.deletePending(this.ticketNumSelected);
        return;
      }
      if (sub?.status && sub?.status === "active") {
        // eslint-disable-next-line
        (window as any).fbq("trackCustom", "PurchaseSubscription");

        this.success = true;

        // send email to user.
        sendPurchaseEmail(this.data);

        // Do this last, as it will redirect
        this.updateTicket();
        store.dispatch("showBanner", { type: "success", message: "Purchase Successful" });
        this.paymentLoading = false;
      } else if (sub?.status && sub?.status === "incomplete") {
        this.stripeValidationError = "Oops - Looks like something went wrong while charging your card.  If the problem persists, please contact your financial institution.";
        store.dispatch("showBanner", {
          type: "error",
          message: this.stripeValidationError,
        });
        await this.deletePending(this.ticketNumSelected);
        this.paymentLoading = false;
      }
    }, (error) => {
      this.paymentLoading = false;
      this.stripeValidationError = error.message;
      // console.log("error");
      // console.log(error);
    });
    // wait for successful completion or 15s to elapse...
    while ((this.paymentLoading === true) && ((Date.now() - now) < 15000)) {
      // eslint-disable-next-line no-await-in-loop
      await sleepNow(1000);
    }

    if (this.paymentLoading === true) {
      // Timed out
      this.paymentLoading = false;
      this.stripeValidationError = "Oops - Looks like something went wrong while charging your card.  If the problem persists, please contact your financial institution.";
      store.dispatch("showBanner", {
        type: "error",
        message: this.stripeValidationError,
      });
      await this.deletePending(this.ticketNumSelected);
    }
  }

  setValidationError(event: TokenResult): void {
    this.stripeValidationError = event.error?.message || "";
    this.paymentLoading = false;
  }

  async updateTicket(): Promise<void> {
    this.query.Filters = [];
    if (this.userState.data.id) {
      this.query.Filters.push({
        filter: "uid",
        condition: "==",
        value: this.userState.data.id,
      });
      const tickets = await getTicketsQuery(this.query);
      const ticket = tickets.find((t) => t.TicketNumber === this.ticketNumSelected);
      if (ticket !== undefined) {
        ticket.status = TicketStatus.InUse;
        await dbRef.tickets.doc(ticket.id).set(ticket);
        store.dispatch("setTickNumSelected", 0);
        this.$router.push("/");
      }
      this.query.Filters = undefined;
    }
  }
}
