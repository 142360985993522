






import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class Banner extends Vue {
  @Getter
  public banner!: {show: boolean, message: string, type: string};
}
