export default class Overview {
  name: string;

  description: string;

  email: string;

  lotteryLicense: string;

  startDate: Date;

  endDate: Date;

  loaded: boolean;

  constructor() {
    this.name = "";
    this.description = "";
    this.email = "";
    this.lotteryLicense = "";
    this.startDate = new Date();
    this.endDate = this.startDate;
    this.loaded = false;
  }
}

export function isGameActive(overview: Overview): boolean {
  // Check start, end , and current date to determine if game is active.
  const dateFrom = parseInt((new Date(overview.startDate).getTime()
  / 1000).toFixed(0), 10);
  const dateTo = parseInt((new Date(overview.endDate).getTime()
  / 1000).toFixed(0), 10);
  const dateCheck = Math.floor(+new Date() / 1000);

  return (dateCheck > dateFrom && dateCheck < dateTo);
}

export function isBeforeStartDate(overview: Overview): boolean {
  const startDate = new Date(overview.startDate).getTime();
  const now = new Date().getTime();

  return (now < startDate);
}

export function isAfterEndDate(overview: Overview): boolean {
  const endDate = new Date(overview.endDate).getTime();
  const now = new Date().getTime();

  return (now > endDate);
}
