/* eslint-disable  @typescript-eslint/no-explicit-any */
import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import Access from "../models/Access";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Contact from "../views/Contact.vue"
import Rules from "../views/Rules.vue"
import PlayNow from "../views/PlayNow.vue"
import HowToPlay from "../views/HowToPlay.vue"
import Checkout from "../views/Checkout.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import MyAccount from "../views/MyAccount.vue";
import Winners from "../views/admin/Winners.vue";
import Stats from "../views/admin/Stats.vue";
import Emails from "../views/admin/Emails.vue";
import store from "../store/index";
import Overview from "../views/admin/AdminOverview.vue";
import Search from "../views/admin/Search.vue";
import { isGameActive } from "../models/Overview";

Vue.use(VueRouter);

export const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      roleReqd: Access.PUBLIC,
      hidden: true,
      gameMustBeActive: false,
    },
  },
  {
    path: "/rules",
    name: "Rules",
    component: Rules,
    meta: {
      roleReqd: Access.PUBLIC,
      gameMustBeActive: false,
    },
  },
  {
    path: "/howToPlay",
    name: "How To Play",
    component: HowToPlay,
    meta: {
      roleReqd: Access.PUBLIC,
      gameMustBeActive: false,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      roleReqd: Access.PUBLIC,
      hidden: true,
      gameMustBeActive: false,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      roleReqd: Access.PUBLIC,
      hidden: false,
      gameMustBeActive: false,
    },
  },
  {
    path: "/myAccount",
    name: "My Account",
    component: MyAccount,
    meta: {
      roleReqd: Access.CUSTOMER,
      gameMustBeActive: false,
      requiresAuth: true,
    },
  },
  {
    path: "/playNow",
    name: "Play Now",
    component: PlayNow,
    meta: {
      hidden: true,
      // roleReqd: Access.ADMIN,
      roleReqd: Access.PUBLIC,
      gameMustBeActive: true,
      requiresAuth: false,
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      // roleReqd: Access.ADMIN,
      roleReqd: Access.CUSTOMER,
      hidden: true,
      gameMustBeActive: true,
      requiresAuth: true,
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: PrivacyPolicy,
    meta: {
      roleReqd: Access.PUBLIC,
      hidden: true,
      gameMustBeActive: false,
    },
  },
  {
    path: "/winners",
    name: "Winners",
    component: Winners,
    meta: {
      roleReqd: Access.ADMIN_WINNERS,
      hidden: true,
      gameMustBeActive: false,
      requiresAuth: true,
    },
  },
  {
    path: "/admin/overview",
    name: "Admin",
    component: Overview,
    meta: {
      roleReqd: Access.ADMIN,
      hidden: false,
      gameMustBeActive: false,
      requiresAuth: true,
    },
  },
  {
    path: "/stats",
    name: "Stats",
    component: Stats,
    meta: {
      roleReqd: Access.ADMIN_REPORTS,
      hidden: true,
      gameMustBeActive: false,
      requiresAuth: true,
    },
  },
  {
    path: "/emails",
    name: "Admin Emails",
    component: Emails,
    meta: {
      roleReqd: Access.ADMIN,
      hidden: true,
      gameMustBeActive: false,
      requiresAuth: true,
    },
  },
  {
    path: "/search",
    name: "Search",
    component: Search,
    meta: {
      roleReqd: Access.ADMIN,
      hidden: true,
    },
  },
  {
    path: "*",
    redirect: "/",
    meta: {
      roleReqd: Access.PUBLIC,
      hidden: true,
      gameMustBeActive: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
});

router.beforeEach((to: Route, from: Route, next) => {
  if (
    to.matched.some((record: any) => record.meta.requiresAuth)
    && !store.getters.userState.loggedIn
  ) {
    // console.log("router fail");
    // If the route requiresAuth and the user is not logged in, then store the dest route
    // and send them to the login page.
    // store the route the user was looking for, so we can send them their if they do login
    sessionStorage.setItem("redirectPath", to.path);
    next("/login");
  // eslint-disable-next-line
  } else if (!(to.meta?.roleReqd & store.getters.userState.access)) {
    // if the user doesn't have the reqd access level, then direct them to the home page
    // console.log("no access - go home");
    next("/");
  } else if (
    // If the game must be activ to go to this route
    // eslint-disable-next-line
    to.matched.some((record: any) => record.meta.gameMustBeActive)
  ) {
    // check if game is active, else go to home page
    // (we may have to wait for the store)
    if (!store.getters.overview.loaded) {
      store.watch(
        (state) => state.overview.loaded,
        (value) => {
          if (value) {
            if (isGameActive(store.getters.overview)) next();
            else next("/");
          }
        },
      );
    } else if (isGameActive(store.getters.overview)) next();
    else next("/");
  } else {
    // else permit the user to go to the route
    next();
  }
});

export default router;
