

























/* eslint-disable  @typescript-eslint/no-unused-vars */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Component, Prop, Vue } from "vue-property-decorator";
import firebase from "firebase";
import Draw from "@/models/Draw";
import db from "@/common/Database";
import User from "@/models/User";
import utils from "@/common/Utils";
import store from "@/store";

interface drawData {
  date: string;
  jackpot: string;
  ticketNumberDrawn: string;
  winnerName: string;
  ticketPurchased: string;
  totalWinnings: string;
  email?: string;
  numTickets?: number;
}
/**
 * Widget to show total prize money for the run and stats regarding each draw.
 * Recommended useage <total-prize-money v-bind:accessFlag="userState.isAdmin"/>
 * if userState.isAdmin is true more information is shown.
 * otherwise deprecated information is displayed for public.
 */
@Component
export default class TotalPrizeMoney extends Vue {
  @Prop({ required: true })
  accessFlag!: boolean;

  draws: Draw[] = [];

  users: User[] = [];

  fields = ["date", "jackpot", "ticketNumberDrawn", "winnerName"];

  fieldsAdmin = [...this.fields, "ticketPurchased", "email", "totalWinnings", "numTickets"];

  drawDatas: Array<drawData> = [];

  totalMoney = [0];

  totalMoneyAwarded = 0;

  async mounted(): Promise<void> {
    const drawsSnapshot = await db.draws.get();
    const currentDraw = (
      await firebase.firestore().collection("CurrentDraw").doc("current").get()
    ).data();

    // Get all the draws and loop thru them
    this.draws = drawsSnapshot.docs.map((doc) => doc.data());
    for (let i = 0; i < this.draws.length; i++) {
      // this.draws.forEach(async (draw, index) => {
      const draw = this.draws[i];
      let name = "No winner";
      // console.log(draw.id);
      // const drawId = draw.id as string;
      const [, endDate] = utils.getDateFromDrawID(draw.id as string);
      const draw_date = endDate.toISOString().slice(0, -14);
      const draw_time = new Date(draw_date).getTime();
      const tixCollectionRef = firebase.firestore().collection(`Draws/${draw.id}/Tickets`);
      // eslint-disable-next-line no-await-in-loop
      const drawTix = (await tixCollectionRef.get());
      // console.log("drawTix");
      // console.log(drawTix.size);
      // console.log(`Paid: ${draw.winning_ticket_paid}`);
      if (this.withinTimeFrame(draw_time)) {
        // if (this.withinTimeFrame(draw_time) && currentDraw?.id !== draw.id) {
        // console.log("got here");
        console.log(currentDraw?.id, draw.id);
        // console.log(draw_date, draw_time);
        // eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
        // First, grab find the Ticket doc, then get the uid from there, and then get the User.
        // console.log(`Testing number: ${draw.winning_number}`);
        // console.log(draw);
        // eslint-disable-next-line
        const ticketSnap = await db.tickets.where("TicketNumber", "==", +draw.winning_number!).get();
        // eslint-disable-next-line
        // const ticketSnap = await db.tickets.where("TicketNumber", "==", 10).get();
        // console.log(ticketSnap);
        let email = "N/A";
        if (!ticketSnap.empty) {
          // console.log("Found ticket.");
          const tix = ticketSnap.docs.map((doc) => doc.data());
          // eslint-disable-next-line
          const userDoc = await db.users.doc(tix[0].uid).get();
          if (userDoc.exists) {
            const user = userDoc.data();
            // console.log("Found user.");
            if (draw.winning_ticket_paid) {
              // console.log("Got a winner.");

              // console.log(draw);
              // console.log(this.users);
              email = user!.email;
              if (this.accessFlag) {
                // Show full name for admins
                name = `${user!.firstName} ${user!.lastName}`;
              } else {
                // Show first name and intial of lastname for public
                name = `${user!.firstName} ${user!.lastName.charAt(0)}`;
              }
              if (draw.draw_completed) {
                this.totalMoneyAwarded += draw.subscription_money
                  + draw.one_time_money
                  + draw.carry_over_money;
                // console.log("tot");
                // console.log(this.totalMoneyAwarded);
              }
            }
          }
        } else console.log("TicketSnap empty");
        const winning_ticket_paid = (draw.winning_ticket_paid ? "Yes" : "No");
        this.totalMoney.push(draw.subscription_money + draw.one_time_money + draw.carry_over_money);
        // this.totalMoneyMax = Math.max(...this.totalMoney);
        this.$set(this.drawDatas, i, {
          date: endDate.toISOString().slice(0, -14), // remove timezone from the date
          jackpot: (
            draw.subscription_money
            + draw.one_time_money
            + draw.carry_over_money
          ).toString(),
          ticketNumberDrawn: (draw.draw_completed ? draw.winning_number : "Pending"),
          ticketPurchased: draw.draw_completed ? winning_ticket_paid : "",
          winnerName: (draw.draw_completed ? name : "Pending"),
          totalWinnings: draw.winning_ticket_paid
            ? (
              (draw.subscription_money + draw.one_time_money + draw.carry_over_money)
                / 2
            ).toString()
            : "0",
          email: draw.draw_completed ? email : "",
          numTickets: drawTix.size,
        });
      }
      // });
    }
  }

  private getMoneyAwarded() : string {
    return (Math.round(this.totalMoneyAwarded * 100) / 200).toFixed(2);
  }

  private withinTimeFrame(draw_time: number) {
    const ret = draw_time > store.state.overview.startDate.getTime()
      && draw_time < store.state.overview.endDate.getTime();
    // console.log(
    //   draw_time,
    //   store.state.overview.startDate.getTime(),
    //   store.state.overview.endDate.getTime(),
    //   ret,
    // );
    return ret;
    // return ((draw_time > store.state.overview.startDate.getTime())
    // && (draw_time < store.state.overview.endDate.getTime()));
  }
}
