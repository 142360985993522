













































































/* eslint-disable*/
import { Component, Vue } from "vue-property-decorator";
import { dbRef, fs } from "@/common/Database";
import User from "@/models/User";
import { Subscription } from "@/models/Subscription";
import { Charge } from "@/models/Charge";
import { Ticket } from "@/models/Ticket";
import store from "@/store";

interface UserSearch extends User {
  subs: Subscription[];
  charges: Charge[];
  // hasSubs: boolean;
  // hasCharges:boolean;
}

interface SubCancel extends Subscription {
  id?: string;
  status?: string;
}

@Component
export default class Search extends Vue {
  users: UserSearch [] = [];

  subs: Subscription [] = [];

  charges: Charge [] = [];

  tickets: Ticket [] = [];

  txtQuery = "";

  numQuery = "";

  emailQuery = "";

  resultsUser = new Array<UserSearch>();

  userFields = ["firstName", "lastName", "customerStripeId", "email", "phone", "show_details"];

  chargeFields = ["Charges","TicketNumber", "customer", "id", "status", "receipt"]

  subFields = ["Subscriptions","TicketNumber", "customer", "id", "status", "cancel_sub"];

  resultsMsg = "Enter something to search";

  showResults = false;

  errorMessage = false;

  async mounted(): Promise<void> {
    await this.setup();
  }

  async setup(): Promise<void> {
    this.users = await dbRef.users.get().then((collection)=> {
      return collection.docs.map((doc) => doc.data()) as UserSearch[] 
    });

    this.subs = await dbRef.subscriptions.get().then((collection)=> {
      return collection.docs.map((doc) => doc.data())
    });

    this.charges = await dbRef.charges.get().then((collection)=> {
      return collection.docs.map((doc) => doc.data())
    });

    this.tickets = await dbRef.tickets.get().then((collection)=> {
      return collection.docs.map((doc) => doc.data())
    });

    // Add the relevant charges and subscription for each user
    this.users.forEach((user) => {
      user.subs = this.subs.filter((sub) =>{
        // add all subscriptions with matching customerStripeID
        // return sub.customer === user.customerStripeId && (sub as SubCancel).status !== "canceled";
        // I think we should show canceled iems as well.
        return sub.customer === user.customerStripeId;
      })
      // if(user.subs.length > 0) {
      //   user.hasSubs = true;
      // }
      user.charges = this.charges.filter((charge) => {
        // add all charges with matching customerStripeID
        return charge.customer === user.customerStripeId
      })
      // if(user.charges.length > 0) {
      //   user.hasCharges = true;
      // }
    })
  }

  search(): void {
    this.resultsUser = Array<UserSearch>();
    this.toggleError();
    if (this.txtQuery !== "") {
      // Search for names
      this.nameSearch();
    }
    if (this.emailQuery !== "") {
      // Search for emails
      this.emailSearch();
    }
    if (this.numQuery !== "") {
      // Search for ticket numbers
      this.ticketSearch();
    }
    // Clear input fields
    this.numQuery = "";
    this.txtQuery = "";
    this.emailQuery = "";
  }

  toggleError(): void {
    // All fields empty raise alert
    if (this.numQuery === "" && this.txtQuery === "" && this.emailQuery === "") {
      const msg = "Please enter something to search";
      this.resultsMessage(msg)
    }
  }

  nameSearch(): void {
    // eslint-disable-next-line
    const name = this.txtQuery.toLowerCase();
    // const name = this.txtQuery.split(" ").map((v) => {
    //   return v.toLowerCase();
    // });
    // Check if the input matches a users first name or last name
    // eslint-disable-next-line
    const user = this.users.filter((t) => t.firstName?.toLowerCase().includes(name)
    || t.lastName?.toLowerCase().includes(name)
    || (t.firstName?.length ? name.includes(t.firstName?.toLowerCase()) : false)
    || (t.lastName?.length ? name.includes(t.lastName?.toLowerCase()) : false));
    if (user) {
      this.resultsUser=user;
      this.showResults = true;
      this.setup();
    } else {
      // name not found toggle alert message
      const msg = "Customer does not exist. Check spelling and try again.";
      this.resultsMessage(msg);
    }
  }

  ticketSearch(): void {
    // Check if ticket number exists
    const ticket = this.tickets.find((t) => t.TicketNumber === parseInt(this.numQuery, 10));
    if (ticket) {
      // Get user id from ticket uid, if customer is found
      // use customerStripeId to find subs/charges
      const user = this.users.find((t) => t.id === ticket?.uid);
      // Only add unique results for tickets
      if (user) {
        if (this.resultsUser.indexOf(user) === -1) {
          this.resultsUser.push(user);
          this.setup();
        }
      }
      this.showResults = true;
    } else {
      // Ticket not found toggle alert message
      const msg = "Ticket Number not in play";
      this.resultsMessage(msg);
    }
  }

  emailSearch() : void {
    // Check if email exists, if it does use the customerStripeId to find related subs/charges
    const email = this.users.filter((t) => t.email.includes(this.emailQuery))
    if (email) {
      this.resultsUser=email;
      this.showResults = true; 
      this.setup();
    } else {
      // email not found toggle alert message
      const msg = "Email not found. Check spelling and try again.";
      this.resultsMessage(msg);
    }
  }

  resultsMessage(msg: string): void {
    this.resultsMsg = msg;
    this.errorMessage = true;
    this.showResults = false;
  }

  toggleAlert(): void {
    if (this.errorMessage) {
      this.errorMessage = false;
    }
  }

  async cancelSub(sub: SubCancel) {
    if (confirm("Are you sure you want to cancel?")) {
      try {
        const subDocID = sub.id;
        const subsRef = await fs.collection("Subscriptions").doc(subDocID).get();
        const subsRefData = subsRef.data() as SubCancel
        console.log(subsRefData.id);
        const adminRef = await fs.collection("Admin").add(
          {   command:"cancelSubscription",
              date: Date.now().toString(),
              sub_id: subsRefData.id
          });
        if (adminRef !== null) {
          store.dispatch("showBanner", {
            type: "info",
            message: `Canceled Subscription to ticket #${sub.TicketNumber}`,
          });
          this.$router.go(0)
        }
      } catch (error: any) {
        console.log("Error canceling");
        console.log((error as Error).message);        
      }
    }
  }

  chargeReceipt(charge: Charge) {
    // TODO add recipt
    // add recipt for charges
    console.log(charge)
  }
}
