import { firestore } from "firebase/app";
import Query from "@/models/Query";
import { dbRef } from "@/common/Database";
import { Ticket } from "../models/Ticket";
import User from "../models/User";

// eslint-disable-next-line import/prefer-default-export
export async function getTicketsQuery(query: Query): Promise<Array<Ticket>> {
  const ref = dbRef.tickets;
  let queryRef: firestore.Query<Ticket> | undefined;

  if (query.Filters) {
    query.Filters.forEach((filt) => {
      queryRef = (queryRef || ref).where(
        filt.filter,
        filt.condition,
        filt.value,
      );
    });
  }

  const mapSnap = await (queryRef || ref).get();
  return mapSnap.docs
    .map((doc) => doc.data())
    .filter((data) => data !== undefined);
}

export async function getUsersQuery(query: Query): Promise<Array<User>> {
  const ref = dbRef.users;
  let queryRef: firestore.Query<User> | undefined;

  if (query.Filters) {
    query.Filters.forEach((filt) => {
      queryRef = (queryRef || ref).where(
        filt.filter,
        filt.condition,
        filt.value,
      );
    });
  }

  const mapSnap = await (queryRef || ref).get();
  return mapSnap.docs
    .map((doc) => doc.data())
    .filter((data) => data !== undefined);
}
