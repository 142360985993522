/* eslint-disable camelcase */
/* Disabled because it is going to cause issues with firestore functions */
import { firestore } from "firebase/app";

export type DataConverter<T> = firestore.FirestoreDataConverter<T>;
export type DocumentData = firestore.DocumentData;
export type QueryDocumentSnapshot<T> = firestore.QueryDocumentSnapshot<T>;

export default class Draw {
  subscription_money: number;

  one_time_money: number;

  carry_over_money: number;

  winning_number: number | undefined;

  winning_ticket_paid: boolean;

  draw_completed: boolean;

  adminUID?: string;

  id?: string;

  constructor() {
    this.subscription_money = 0;
    this.carry_over_money = 0;
    this.one_time_money = 0;
    this.winning_number = undefined;
    this.winning_ticket_paid = false;
    this.draw_completed = false;

    this.adminUID = "";
  }
}
