/* eslint-disable @typescript-eslint/no-explicit-any */
import firebase, { firestore } from "firebase/app";
import User from "@/models/User";
import Overview from "@/models/Overview";
import Draw from "@/models/Draw";
import "firebase/firestore";
import { Charge } from "@/models/Charge";
import { Subscription } from "@/models/Subscription";
import { Ticket } from "../models/Ticket"

require("firebase/auth");

let firebaseLocalApp: any | undefined;

if (!firebase.apps.length) {
  const config = {
    apiKey: process.env.VUE_APP_FB_APIKEY,
    authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_FB_DATABASEURL,
    projectId: process.env.VUE_APP_FB_PROJECTID,
    storageBucket: process.env.VUE_APP_FB_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FB_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_FB_APPID,
  };

  firebaseLocalApp = firebase.initializeApp(config);

  if (config.databaseURL === "http://localhost:8080") {
    const db = firebase.firestore();
    db.settings({
      host: "localhost:8080",
      ssl: false,
    });
    const auth = firebase.auth();
    auth.useEmulator("http://localhost:9099");
  }
}

export const firebaseApp = firebaseLocalApp;
export const fb = firebase;
export const fs = firebase.firestore();

function convertTo(value: any): any {
  let val = value;
  // An array of strings that represent all the enumerable properties of the given object.
  const objKeysArr = Object.keys(value);
  let isRecursive = false;
  if (objKeysArr.length) {
    isRecursive = true;
  }
  if (value instanceof Date) {
    val = firestore.Timestamp.fromDate(value);
  } else if (isRecursive && objKeysArr.length > 0 && typeof value !== "string") {
    objKeysArr.forEach((key) => {
      val[key] = convertTo(value[key])
    });
  }
  return val;
}

function convertFrom(value: any): any {
  let val = value;
  if (val === null) {
    return val;
  }
  const objKeysArr = Object.keys(value);
  let isRecursive = false;
  if (objKeysArr.length) {
    isRecursive = true;
  }
  if (value instanceof firestore.Timestamp) {
    val = value.toDate();
  } else if (isRecursive && objKeysArr.length > 0 && typeof value !== "string") {
    objKeysArr.forEach((key) => {
      val[key] = convertFrom(value[key])
    })
  }
  return val;
}

const converter = <
  T extends { id?: string; CreatedAt?: firestore.Timestamp }
>() => ({
    toFirestore(data: Partial<T>): firebase.firestore.DocumentData {
      let theData = data;
      if (Object.keys(data).includes("id")) {
        delete theData.id;
      }
      theData = {
        ...theData,
        CreatedAt: data.CreatedAt || firestore.Timestamp.now(),
        UpdatedAt: firestore.Timestamp.now(),
      };
      theData = convertTo(theData);
      return theData;
    },
    fromFirestore(snap: firebase.firestore.QueryDocumentSnapshot): T {
      let fsData = snap.data();
      fsData = convertFrom(fsData);
      return { ...(fsData as T), id: snap.id };
    },
  });

const dataPoint = <T>(collectionPath: string) =>
  // DO NOT REMOVE - Essential exception here for database conflicts:
  // eslint-disable-next-line implicit-arrow-linebreak
  firebase.firestore().collection(collectionPath).withConverter(converter<T>());

const dbRef = {
  tickets: dataPoint<Ticket>("Tickets"),
  users: dataPoint<User>("Users"),
  charges: dataPoint<Charge>("Charges"),
  subscriptions: dataPoint<Subscription>("Subscriptions"),
  draws: dataPoint<Draw>("Draws"),
  overview: dataPoint<Overview>("Overview"),
};

export { dbRef };
export default dbRef;
