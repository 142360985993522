

















































































import { Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import store from "@/store";

export default class CustomFooter extends Vue {
  // ! enforces no undefined on getters
  @Getter
  public loggedIn!: boolean;

  private BuildDate = document.documentElement.dataset.buildTimestamp;

  private userLoggedin() : boolean {
    if (store.state.userState.data) {
      return true;
    }
    return false;
  }
}
