

































import { Component, Vue, Watch } from "vue-property-decorator";
import firebase from "firebase";
import { Getter } from "vuex-class";
import { fs } from "@/common/Database";
import store, { UserState } from "@/store";
// import { Ticket, TicketStatus } from "@/models/Ticket";
import Draw from "@/models/Draw";
import {
  emailTemplates, sendContactFormEmail, sendPurchaseEmail, sendUnpaidNumber,
  sendWelcomeEmail, sendWinnerEmail,
} from "@/common/email";

interface mailTableData {
  To: number;
  Date: string;
  Status: string;
  Attempts: string;
  id: string;
}

@Component
export default class Stats extends Vue {
  @Getter
  userState!: UserState;

  @Getter
  currentDraw!: string;

  lastDraw: Draw = new Draw();

  lastDrawDates: Date[] = [];

  status = "";

  emailType = "";

  emailTypes: Array<string> = [];

  emails: Array<mailTableData> = [];

  // eslint-disable-next-line
  emailFields: Array<any> = [
    {
      key: "To",
      sortable: true,
    },
    {
      key: "Date",
      sortable: true,
    },
    {
      key: "Status",
      sortable: true,
    },
    {
      key: "Attempts",
      sortable: true,
    },
    "Retry",
  ];

  @Watch("emailType")
  async onTypeChanged(type: string): Promise<void> {
    // Load selected draw
    const mailSnap = await fs
      .collection("mail")
      .where("template.name", "==", type)
      .orderBy("delivery.startTime", "desc")
      .get();

    this.emails = [];
    if (!mailSnap.empty) {
      mailSnap.forEach(
        (doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
          const mail = doc.data();
          if (mail) {
            this.emails.push({
              To: mail.to,
              Date: mail.delivery.startTime ? mail.delivery.startTime.toDate().toISOString() : "NA",
              Status: mail.delivery.state,
              Attempts: mail.delivery.attempts,
              id: doc.id,
            });
          }
        },
      );
    }
  }

  async created(): Promise<void> {
    // Load email types
    Object.values(emailTemplates).forEach((v) => this.emailTypes.push(v));
  }

  retryEmail(id: string): void {
    // console.log(id);
    // jusy need to toggle the retry bits on the table here
    // change delivery.state to "RETRY"
    fs.collection("mail").doc(id).update({ delivery: { state: "RETRY" } });
    // Should do a toast or something here just for user feedback
  }

  getDateFromDrawID(id: string): Date[] {
    const dates = id.split("-");
    const drawStartDate = new Date(
      parseInt(dates[0].substring(0, 4), 10),
      // our dates are numbers representing months starting at 1
      // js Date object requires them to start at 0
      parseInt(dates[0].substring(4, 6), 10) - 1,
      parseInt(dates[0].substring(6), 10),
    );
    const drawEndDate = new Date(
      parseInt(dates[1].substring(0, 4), 10),
      // our dates are numbers representing months starting at 1
      // js Date object requires them to start at 0
      parseInt(dates[1].substring(4, 6), 10) - 1,
      parseInt(dates[1].substring(6), 10),
    );
    return [drawStartDate, drawEndDate];
  }

  // Sends email to admins for testing purpose
  sendEmails(): void {
    sendWelcomeEmail(store.state.userState.data.email, emailTemplates.WELCOME);
    sendPurchaseEmail(store.state.userState.data, emailTemplates.PURCHASE);
    sendWinnerEmail(store.state.userState.data, 500, emailTemplates.WINNER);
    sendUnpaidNumber(store.state.userState.data, emailTemplates.REMINDER);
    sendContactFormEmail(store.state.userState.data.firstName,
      store.state.userState.data.email, "sendContactFormEmail() function test successful");
  }
}
