import firebase, { firestore } from "firebase/app";
import WhereFilterOp = firebase.firestore.WhereFilterOp;

export default interface Query {
  CreationDate: string;
  email: string;
  Filters?: Array<{
  filter: string | firestore.FieldPath;
    condition: WhereFilterOp;
    value: string | Date | Array<string>;
  }>;
}

export const query: Query = {
  CreationDate: "CreationDate",
  email: "email",
  Filters: undefined,
};
