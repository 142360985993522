



























































































































































































































































































/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Vimeo } from "vimeo";
import utils from "@/common/Utils";
import db from "@/common/Database";
import store from "@/store";
import {
  isAfterEndDate,
  isBeforeStartDate,
  isGameActive,
} from "@/models/Overview";

interface vimeoCard {
  thumbnail: string;
  created: Date;
  embedContent: string;
  name: string;
}

@Component
export default class Home extends Vue {
  private vimeoCards: Array<vimeoCard> = [];

  private vimeoURL = "";

  private remainingDays = "";

  private remainingHours = "";

  private remainingMinutes = "";

  @Getter
  public currentJackpot!: number;

  @Getter
  public currentDraw!: string;

  private lastDrawId = "";

  private lastWinningNumberText = "";

  private isMobile = utils.isMobile();

  drawChanged(drawID: string): void {
    let lastDrawID;
    if (this.isAfter()) {
      lastDrawID = drawID;
    } else {
      lastDrawID = utils.GetLastDrawID(drawID);
    }
    db.draws.doc(lastDrawID).onSnapshot((snap) => {
      if (snap.exists) {
        const draw = snap.data();
        if (draw && draw.winning_number) {
          if (draw.winning_ticket_paid) {
            this.lastWinningNumberText = `${draw.winning_number.toString()} Winner!`;
          } else {
            this.lastWinningNumberText = `${draw.winning_number.toString()} (No Winner)`;
          }
        }
      }
    });
  }

  async created(): Promise<void> {
    const client = new Vimeo(
      "9767468e9c2d8f62d8569040aaf7b8da98c016fe",
      "WLU2aOAguEYsilTYC4bRQBZCG4C/NQGLQFjBuUY7njjjQfESJDU45RJT5RblZFf6bHwyHUhS8nRIJbdQLpSviwpTcXz12VZtQYQsxMrxmJdEyWjptBw6yQifKrfmJPH+",
      "b47a29ae4d46e71af2cc3c7bccd55d08",
    );

    // https://developer.vimeo.com/api/reference/videos/3.4#get_videos
    client.request(
      {
        method: "GET",
        path: "/users/170185100/videos",
      },
      // eslint-disable-next-line
      (error: string | undefined, result: any) => {
        if (error) {
          // error doing Vimeo
        }
        // eslint-disable-next-line
        result.data.forEach((v: any) => {
          if (v.name.toLowerCase().includes("week")) {
            if (!this.vimeoURL.length) {
              this.setVimeoURL(v.embed.html);
            }

            this.vimeoCards.push({
              thumbnail: v.pictures.sizes[1].link_with_play_button,
              created: new Date(v.created),
              embedContent: v.embed.html,
              name: v.name,
            });
          }
        });
      },
    );

    if (!this.currentDraw) {
      store.watch(
        (state) => state.currentDraw,
        (value) => {
          if (value) {
            this.drawChanged(value);
          }
        },
      );
    } else {
      this.drawChanged(this.currentDraw);
    }

    // If the Overview hasn't been fully loaded yet then we must wait for it.
    // Check if there is an Overview object and that the startDate member is of type Date
    // (it seems to be initially created as a string, but changes to a Date once fully
    // loaded)
    if (
      !store.state.overview
      || !(store.state.overview.startDate instanceof Date)
    ) {
      store.watch(
        (state) => state.overview!.startDate,
        (value) => {
          if (value) {
            // Countdown timer widget
            this.calculateRemainingTime();
            setInterval(this.calculateRemainingTime, 10000);
          }
        },
        {
          deep: true,
        },
      );
    } else {
      // Countdown timer widget
      this.calculateRemainingTime();
      setInterval(this.calculateRemainingTime, 10000);
    }
  }

  private calculateRemainingTime(): void {
    const newDate = new Date(store.state.overview.startDate);
    const now = new Date().getTime();
    const startDate = newDate.getTime();
    const timeDiff = startDate - now;

    const remainingDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const remainingHours = Math.floor(
      (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const remainingMinutes = Math.floor(
      (timeDiff % (1000 * 60 * 60)) / (1000 * 60),
    );

    this.remainingDays = `${String(remainingDays).padStart(2, "0")}`;
    this.remainingHours = `${String(remainingHours).padStart(2, "0")}`;
    this.remainingMinutes = `${String(remainingMinutes).padStart(2, "0")}`;
  }

  private isActive() {
    return isGameActive(store.state.overview);
  }

  private isBefore() {
    return isBeforeStartDate(store.state.overview);
  }

  private isAfter() {
    return isAfterEndDate(store.state.overview);
  }

  setVimeoURL(url: string): void {
    const [, src] = url.split("\"");
    this.vimeoURL = src;
  }

  redirect(): void {
    this.$router.push("/playNow");
  }
}
