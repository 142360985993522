
















































import { Component, Vue, Watch } from "vue-property-decorator";
import firebase from "firebase";
import { Getter } from "vuex-class";
import db, { fs } from "@/common/Database";
import { UserState } from "@/store";
// import { Ticket, TicketStatus } from "@/models/Ticket";
import Draw from "@/models/Draw";
import utils from "@/common/Utils";

interface subTableData {
  Num: number;
  Date: string;
  Status: string;
  Email: string;
  id?: string;
}

interface drawTableData {
  Num: number;
  Date: string;
  Type: string;
  Email: string;
}

@Component
export default class Stats extends Vue {
  @Getter
  userState!: UserState;

  @Getter
  currentDraw!: string;

  lastDraw: Draw = new Draw();

  lastDrawDates: Date[] = [];

  status = "";

  oneTimes: Array<subTableData> = [];

  allOneTimes: Array<subTableData> = [];

  subscriptions: Array<subTableData> = [];

  allSubscriptions: Array<subTableData> = [];

  drawTickets: Array<drawTableData> = [];

  selectedDraw = "";

  draws: Array<{ value: Draw; text: string }> = [];

  syncSubscriptionsWithDraws = false;

  syncOneTimesWithDraws = false;

  // eslint-disable-next-line
  fields: Array<any> = [
    {
      key: "Num",
      sortable: true,
    },
    {
      key: "Date",
      sortable: true,
    },
    {
      key: "Status",
      sortable: true,
      // Variant applies to the whole column, including the header and footer
      // variant: 'danger'
    },
    {
      key: "Email",
      sortable: true,
    },
  ];

  // eslint-disable-next-line
  drawFields: Array<any> = [
    {
      key: "Num",
      sortable: true,
    },
    {
      key: "Date",
      sortable: true,
    },
    {
      key: "Type",
      sortable: true,
    },
    {
      key: "Email",
      sortable: true,
    },
  ];

  @Watch("selectedDraw")
  async onSelectedDrawChanged(draw: Draw): Promise<void> {
    // Load selected draw
    const allPaidSnap = await fs
      .collection("Draws")
      .doc(draw.id)
      .collection("Tickets")
      .get();

    this.drawTickets = [];
    if (!allPaidSnap.empty) {
      allPaidSnap.forEach(
        (doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
          const ticket = doc.data();
          if (ticket) {
            this.drawTickets.push({
              Num: parseInt(doc.id, 10),
              Date: ticket.date ? new Date(ticket?.date * 1000).toISOString() : "pending",
              Type: ticket?.type,
              Email: ticket?.customerStripeId,
            });
            console.log(`${parseInt(doc.id, 10)},${ticket?.type},${ticket?.customerStripeId}`);
          }
        },
      );
    }

    if (this.syncOneTimesWithDraws) {
      // update the subscriptions list
      const [startDate, endDate] = utils.getDateFromDrawID(draw.id as string);
      this.oneTimes = this.allOneTimes.filter(
        (s) => s.Date >= startDate.toISOString() && s.Date <= endDate.toISOString(),
      );
    }

    if (this.syncSubscriptionsWithDraws) {
      // update the subscriptions list
      const [startDate, endDate] = utils.getDateFromDrawID(draw.id as string);
      this.subscriptions = this.allSubscriptions.filter(
        (s) => s.Date >= startDate.toISOString() && s.Date <= endDate.toISOString(),
      );
    }
  }

  async created(): Promise<void> {
    // Load Subscriptions
    const subscriptionSnapshot = await fs.collection("Subscriptions").get();
    const subs = subscriptionSnapshot.docs.map((doc) => doc.data());

    subs.forEach(async (s) => {
      const userSnap = await db.users.where("customerStripeId", "==", s.customer).get();
      let email = "<undefined>";
      if (!userSnap.empty) {
        const doc = userSnap.docs[0];
        const user = doc.data();
        email = user.email;
      }

      let time = "";
      try {
        time = new Date(s.created * 1000).toISOString();
      } catch (error) {
        console.log("time error:");
        console.log(s);
      }

      this.subscriptions.push({
        Num: s.TicketNumber,
        Date: time,
        Status: s.status,
        Email: email,
        id: s.id,
      });

      this.allSubscriptions.push({
        Num: s.TicketNumber,
        Date: time,
        Status: s.status,
        Email: email,
        id: s.id,
      });
    });

    this.subscriptions.sort((a: subTableData, b: subTableData) => a.Num - b.Num);

    // Load OneTimes
    const oneTimeSnapshot = await fs.collection("Charges").get();
    const ones = oneTimeSnapshot.docs.map((doc) => doc.data());

    ones.forEach(async (s) => {
      const userSnap = await db.users.where("customerStripeId", "==", s.customer).get();
      let email = "<undefined>";
      if (!userSnap.empty) {
        const doc = userSnap.docs[0];
        const user = doc.data();
        email = user.email;
      }

      let time = "";
      try {
        time = new Date(s.created * 1000).toISOString();
      } catch (error) {
        console.log("time error:");
        console.log(s);
      }

      this.oneTimes.push({
        Num: s.TicketNumber,
        Date: time,
        Status: s.status,
        Email: email,
      });

      this.allOneTimes.push({
        Num: s.TicketNumber,
        Date: time,
        Status: s.status,
        Email: email,
      });
    });

    this.oneTimes.sort((a: subTableData, b: subTableData) => a.Num - b.Num);

    // Now load Draws
    const activeDraws = await (await db.draws.get()).docs;
    activeDraws.forEach((draw: firebase.firestore.QueryDocumentSnapshot<Draw>) => {
      this.draws.push({ value: { id: draw.id, ...draw.data() }, text: draw.id });
    });
  }

  exportCSV(items: Array<subTableData>, title: string): void {
    const csvData = items.map((i) => (Object.values(i)));

    // Sort array first
    // csvData.sort(
    //   (a: string[], b: string[]) => new Date(a[1]).getTime() - new Date(b[1]).getTime(),
    // );
    const array = csvData.map(
      (entry: string[]) => `${entry.map((item: string) => `"${item}"`)}\n`,
    );
    const csvFile = new Blob([...array], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    // sets the name for the download file
    const date = new Date();
    const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
    downloadLink.download = `${dateString}-${title}-report.csv`;
    // sets the url to the window URL created from csv file above
    downloadLink.href = window.URL.createObjectURL(csvFile);
    // creates link, but does not display it.
    downloadLink.style.display = "none";
    // add link to body so click function below works
    document.body.appendChild(downloadLink);

    downloadLink.click();
  }

  getDateFromDrawID(id: string): Date[] {
    const dates = id.split("-");
    const drawStartDate = new Date(
      parseInt(dates[0].substring(0, 4), 10),
      // our dates are numbers representing months starting at 1
      // js Date object requires them to start at 0
      parseInt(dates[0].substring(4, 6), 10) - 1,
      parseInt(dates[0].substring(6), 10),
    );
    const drawEndDate = new Date(
      parseInt(dates[1].substring(0, 4), 10),
      // our dates are numbers representing months starting at 1
      // js Date object requires them to start at 0
      parseInt(dates[1].substring(4, 6), 10) - 1,
      parseInt(dates[1].substring(6), 10),
    );
    return [drawStartDate, drawEndDate];
  }
}
