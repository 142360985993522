












































































































































































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Charge } from "@/models/Charge";
import { Subscription } from "@/models/Subscription";
import db, { dbRef } from "@/common/Database";
import { UserState } from "@/store";
import User from "@/models/User";
import Access from "@/models/Access";

@Component
export default class MyAccount extends Vue {
  public regex = new RegExp(/^[A]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);

  isVisible = [true, false, false];

  subscriptions: Subscription[] = [];

  charges: Charge[] = [];

  // ! enforces no undefined on getters
  @Getter
  public userState!: UserState;

  public userModel: User = {
    firstName: "",
    lastName: "",
    email: "",
    customerStripeId: "",
    remindersEnabled: true,
    address: {
      Street: "",
      City: "",
      Province: "",
      PostalCode: "",
    },
    phone: "",
    role: Access.PUBLIC,
    fromWordpress: false,
  };

  public editing = false;

  async mounted(): Promise<void> {
    this.userModel = this.userState.data;
    const chargeSnapshot = await db.charges
      .where("customer", "==", this.userModel.customerStripeId)
      .get();
    const subscriptionSnapshot = await db.subscriptions
      .where("customer", "==", this.userModel.customerStripeId)
      .get();
    this.charges = chargeSnapshot.docs.map((doc) => doc.data());
    this.subscriptions = subscriptionSnapshot.docs.map((doc) => doc.data());
  }

  async onSubmit(e: Event): Promise<void> {
    e.preventDefault();
    this.editing = !this.editing;
    // dont submit form if editing has just started
    if (this.editing) {
      return;
    }
    await dbRef.users.doc(this.userModel.id).update({ ...this.userModel });
  }

  get validPostalCode(): boolean {
    let vPcode = true;
    if (!this.regex.test(this.userModel.address.PostalCode.trim())) {
      vPcode = false;
    }
    return vPcode;
  }

  get computedEvalForm(): boolean {
    let registIsValid = true;
    if (!this.regex.test(this.userModel.address.PostalCode.trim())) {
      registIsValid = false;
    } else if (!this.userModel.address.Street.trim().length) {
      registIsValid = false;
    } else if (!this.userModel.address.City.trim().length) {
      registIsValid = false;
    } else if (!this.userModel.firstName.length) {
      registIsValid = false;
    } else if (!this.userModel.lastName.length) {
      registIsValid = false;
    } else if (!this.userModel.email.length) {
      registIsValid = false;
    }
    return registIsValid;
  }
}
