


























































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import firebase from "firebase/app";
import "firebase/auth";
import { Getter } from "vuex-class";
import {
  dbRef, firebaseApp,
} from "@/common/Database";
import User from "@/models/User";
import Access from "@/models/Access";
import store, { UserState } from "@/store";
import { query } from "@/models/Query";
import { getTicketsQuery } from "@/common/QueryClient";
import { Ticket } from "@/models/Ticket";
import { sendWelcomeEmail } from "@/common/email";

// used to get around a circular dependency
let router = null;

@Component
export default class Login extends Vue {
  user: User = new User();

  newUser = true;

  message = "Only open to residents of Newfoundland and Labrador";

  ticketMessageBegin = "Register";

  ticketMessageEnd = "your number";

  error = "";

  errorLogin = "";

  psswrdStr = 0;

  regUserEmail = "";

  regUserPassword = "";

  confEmail = "";

  newUserPassword = "";

  showPass = false;

  confPassword = "";

  resetSuccess = false;

  forgotPass = false;

  provinceText = "NL";

  usrAccess: Array<number> = [];

  @Getter
  public ticketNumSelected!: number;

  @Getter
  public userState!: UserState;

  @Getter
  public loggedIn!: boolean;

  created(): void {
    router = this.$router;
    if (this.loggedIn) {
      this.$router.push("/playNow");
    }
    if (this.userState.ticketNumSelected) {
      this.newUser = true;
      this.ticketMessageBegin = "Register now";
      this.ticketMessageEnd = `number ${this.userState.ticketNumSelected}`;
    } else {
      this.ticketMessageBegin = "Login";
      this.newUser = false;
    }
  }

  toggleNewUser(): void {
    this.newUser = !this.newUser;
    if (this.ticketMessageBegin === "Login") {
      this.ticketMessageBegin = "Register now";
    } else {
      this.ticketMessageBegin = "Login";
    }
  }

  get isValidPassword(): string {
    let passErrMsg = "";
    if (this.newUserPassword.trim().length && this.newUserPassword.trim().length < 6) {
      passErrMsg = "Password is too short";
    }
    return passErrMsg;
  }

  get matchPassword(): string {
    let matchErr = "";
    if (
      this.confPassword.trim().length
      && this.newUserPassword.trim() !== this.confPassword.trim()
    ) {
      matchErr = "Passwords do not match";
    }
    return matchErr;
  }

  get isValidPostalCode(): string {
    let pcodeErrMsg = "";
    if (this.user.address.PostalCode.trim().length && !this.validPostalCode()) {
      pcodeErrMsg = "Not a valid postal code in NL";
    }
    return pcodeErrMsg;
  }

  get computedEvalForm(): boolean {
    let registIsValid = true;
    if (this.formIsEmpty) {
      this.error = "All * fields are required!";
      registIsValid = false;
    } else if (!this.validPostalCode()) {
      registIsValid = false;
      this.error = "Invalid postal code in NL";
    } else if (!this.isValidEmail()) {
      registIsValid = false;
      this.error = "Not a valid email format";
    } else if (this.confEmail.trim() !== this.user.email) {
      registIsValid = false;
      this.error = "Email addresses do not match";
    } else if (this.confPassword.trim() !== this.newUserPassword) {
      this.error = "Passwords do not match";
      registIsValid = false;
    } else if (this.newUserPassword.trim().length <= 5) {
      this.error = "Password is not strong enough; should be at least 6 characters";
      registIsValid = false;
    } else if (!this.user.address.Street.trim().length) {
      this.error = "'Street' field in 'Address' section is not filled in";
      registIsValid = false;
    } else if (!this.user.address.City.trim().length) {
      this.error = "'City' field in 'Address' section is not filled in";
      registIsValid = false;
    } else if (registIsValid) {
      this.error = "";
    }
    return registIsValid;
  }

  validPostalCode(): boolean {
    let vPcode = false;
    const regex = new RegExp(/^[A]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
    if (regex.test(this.user.address.PostalCode.trim())) {
      vPcode = true;
    }
    return vPcode;
  }

  isValidEmail(): boolean {
    let vEmail = false;
    const emailPattern = new RegExp(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b/);
    if (emailPattern.test(this.user.email)) {
      vEmail = true;
    }
    return vEmail;
  }

  get formIsEmpty(): boolean {
    if (
      this.user.address.City.trim().length
      && this.user.address.PostalCode.trim().length
      && this.user.address.Street.trim().length
      && this.user.firstName.trim().length
      && this.user.lastName.trim().length
      && this.user.email.trim().length
      && this.user.phone.trim().length
      && this.newUserPassword.trim().length
      && this.confPassword.trim().length
    ) {
      return false;
    }
    return true;
  }

  async submitRegister(_event: Event): Promise<void> {
    // event.preventDefault();
    firebase.auth()
      .createUserWithEmailAndPassword(this.user.email, this.newUserPassword)
      .then((userCredential) => {
        const createdUser = userCredential.user;
        if (createdUser !== null) {
          createdUser
            .updateProfile({ displayName: `${this.user.firstName} ${this.user.lastName}` })
            .then(async () => {
              this.scrollToTop();
              this.clearFields();
              const existingUserQuery = await dbRef.users
                .where("email", "==", this.user.email)
                .get();
              if (!existingUserQuery.empty) {
                const existingUser = existingUserQuery.docs[0];
                if (existingUser.data().fromWordpress) {
                  dbRef.users.doc(createdUser.uid).set({
                    remindersEnabled: true,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    email: createdUser.email as string,
                    address: this.user.address,
                    phone: this.user.phone.toString(),
                    customerStripeId: this.user.customerStripeId,
                    // eslint-disable-next-line
                    role: Access.CUSTOMER | Access.PUBLIC,
                    fromWordpress: false,
                  });
                  dbRef.tickets
                    .where("uid", "==", existingUser.id)
                    .get()
                    .then((snap) => {
                      snap.docs.forEach((ticket) => {
                        ticket.ref.update({ uid: createdUser.uid });
                      });
                    });
                  dbRef.users.doc(existingUser.id).delete();
                }
              } else {
                await dbRef.users.doc(createdUser.uid).set({
                  remindersEnabled: true,
                  firstName: this.user.firstName,
                  lastName: this.user.lastName,
                  email: createdUser.email as string,
                  address: this.user.address,
                  phone: this.user.phone.toString(),
                  customerStripeId: this.user.customerStripeId,
                  // eslint-disable-next-line
                  role: Access.CUSTOMER | Access.PUBLIC,
                  fromWordpress: false,
                });
                if (this.ticketNumSelected) {
                  this.addUserToTicket(createdUser.uid);
                  this.$router.push("/checkout");
                } else {
                  this.$router.push("/playNow");
                }
              }
              // eslint-disable-next-line
              (window as any).fbq("trackCustom", "Register");
              sendWelcomeEmail(this.user.email);
            });
        }
      })
      .catch((e: Error) => {
        this.error = e.message;
      });
  }

  submitLogin(_event: Event): void {
    // event.preventDefault();

    firebase.auth()
      .signInWithEmailAndPassword(this.regUserEmail, this.regUserPassword)
      .then(async () => {
        // if there's a number selected, we have to wait for the store
        // user to be updated...
        if (this.ticketNumSelected) {
          if (!this.userState.data) {
            store.watch(
              (state) => state.userState.data,
              (value) => {
                if (value) {
                  this.addUserToTicket(this.userState.data.id as string);
                  this.$router.push("/checkout");
                }
              },
            );
          } else {
            this.addUserToTicket(this.userState.data.id as string);
            this.$router.push("/checkout");
          }
        } else {
          this.$router.push("/playNow");
        }
      })
      .catch((e: Error) => {
        if (
          e.message
          === "There is no user record corresponding to this identifier. The user may have been deleted."
        ) {
          this.errorLogin = "Email or Password is incorrect";
        } else {
          this.errorLogin = e.message;
        }
      });
  }

  showScore(score: number): void {
    this.psswrdStr = score;

    if (score < 3) {
      this.error = "Password is not strong enough.";
    } else {
      this.error = "";
    }
  }

  passRules(): unknown {
    if (!/.+@.+\..+/.test(this.newUserPassword)) {
      this.error = "common.fieldreq";
    }
    return [(v: unknown) => !!v || this.error];
  }

  forgotPassword(): void {
    this.forgotPass = true;
  }

  onlyNL(): void {
    this.provinceText = "Newfoundland & Labrador residents only";
  }

  passwordChange(): void {
    this.errorLogin = "";
    this.resetSuccess = false;
    if (new RegExp(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}\b/).test(this.regUserEmail)) {
      firebase.auth()
        .sendPasswordResetEmail(this.regUserEmail)
        .then(() => {
          this.errorLogin = "";
          this.resetSuccess = true;
        })
        .catch((error: Error) => {
          if (
            error.message
            === "There is no user record corresponding to this identifier. The user may have been deleted."
          ) {
            this.errorLogin = "Couldn't find your email address";
          } else {
            this.errorLogin = error.message;
          }
        });
    }
    this.scrollToTop();
    this.errorLogin = "Please provide a valid Email address";
  }

  showModal(): void {
    this.$bvModal.show("passChangeModal");
  }

  cancelPassChange(): void {
    this.$bvModal.hide("passChangeModal");
  }

  async addUserToTicket(currentUserId: string): Promise<void> {
    const existingTicks: Ticket[] = await getTicketsQuery(query);
    const ticket = existingTicks.find((t) => t.TicketNumber === this.ticketNumSelected);
    if (currentUserId && ticket !== undefined) {
      ticket.uid = currentUserId;
      dbRef.tickets.doc(ticket.id).update(ticket);
    }
  }

  scrollToTop(): void {
    window.scrollTo(10, 10);
  }

  clearFields(): void {
    this.regUserEmail = this.user.email;
    this.regUserPassword = "";
  }
}

// This is the firebase callback that gets triggered
// whenever there is a login state change.  Here is *the* spot to handle
// all login/logout logic.
firebaseApp.auth().onAuthStateChanged(async (user: firebase.User) => {
  if (user) {
    // login event
    dbRef.users
      .doc(user?.uid)
      // We use a snapshot here to handle the async case where the
      // firebase user is created before the db user.
      .onSnapshot((dbUser) => {
        if (dbUser.exists) {
          const t2rUser = dbUser.data() as User;
          // fix up reminderEnabled here, as we added it to the user model later...
          if (!("remindersEnabled" in t2rUser)) t2rUser.remindersEnabled = true;
          store.dispatch("fetchUser", { ...t2rUser, id: dbUser.id });
          // eslint-disable-next-line
          store.dispatch("setRole", (t2rUser?.role || Access.CUSTOMER) | Access.PUBLIC);

          const StoredRoute = sessionStorage.getItem("redirectPath");
          if (StoredRoute) router.push(StoredRoute);
          sessionStorage.removeItem("redirectPath");
        }
      });
  } else {
    // logout event - make sure the store gets cleaned out
    store.dispatch("fetchUser", null);
  }
});
