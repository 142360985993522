import { firestore } from "firebase/app";

export type DataConverter<T> = firestore.FirestoreDataConverter<T>;
export type DocumentData = firestore.DocumentData;
export type QueryDocumentSnapshot<T> = firestore.QueryDocumentSnapshot<T>;

export enum TicketStatus {
  Reserved = "Reserved",
  InUse = "InUse",
  PlayedThisWeek = "PlayedThisWeek",
}
export class Ticket {
  id?: string;

  uid: string;

  TicketNumber: number;

  status: string;

  CreatedAt?: string;

  constructor(ticket = 0, uid = "", status = "") {
    this.uid = uid;
    this.TicketNumber = ticket;
    this.status = status;
  }
}
