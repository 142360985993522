import Access from "./Access";

export default class User {
  firstName: string;

  lastName: string;

  email: string;

  customerStripeId: string;

  address: {
    Street: string;
    City: string;
    Province: string;
    PostalCode: string;
  };

  phone: string;

  id?: string;

  fromWordpress: boolean;

  role: number;

  remindersEnabled: boolean;

  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.customerStripeId = "";
    this.address = {
      Street: "",
      City: "",
      Province: "NL",
      PostalCode: "",
    };
    this.remindersEnabled = true;
    this.phone = "";
    // eslint-disable-next-line
    this.role = Access.CUSTOMER | Access.PUBLIC;
    this.fromWordpress = false;
  }
}
